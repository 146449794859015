import React, { useEffect } from 'react';
import './policy.css' // Assuming you have a CSS file for styling
import axios from 'axios';
import { Base_URL } from 'constant/Constant';

const Terms = () => {
    const [data, setData] = React.useState(null);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios
            .get(`${Base_URL}/api/page/${1}`)
            .then((res) => {
                if (res.data.success) {
                    setData(res.data.data);
                }
            })
            .catch((err) => console.error(err));
    };

    return (
        <div className="privacy-container">
            <div className="top-section">
                <h1>Terms of Service</h1>
                {/* <p>Last updated: Feb 5th, 2024.</p> */}
                <p>{data?.title }</p>
            </div>
            <div className="privacy-container-wrapper">
            <div className="policy-details">
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            </div>
            </div>
        </div>
    )
}

export default Terms
