// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.second_sec_text {
  line-height: 1.7em;
}

.App {
  transition: transform 1s;
}

.slide-in {
  transform: translateY(0);
}

.slide-out {
  transform: translateY(-50%);
}


.bold-text-style {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/views/landing/style.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;;AAGA;EACE,iCAAiC;EACjC,gBAAgB;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\n.second_sec_text {\n  line-height: 1.7em;\n}\n\n.App {\n  transition: transform 1s;\n}\n\n.slide-in {\n  transform: translateY(0);\n}\n\n.slide-out {\n  transform: translateY(-50%);\n}\n\n\n.bold-text-style {\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
