import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";


const Featured = () => {

  const [featuredPost, setFeaturedPost] = useState([]);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchFeaturedPosts = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts`);
        // Assuming the response data is in the format you provided
        const allPosts = res.data;
        const filteredFeaturedPosts = allPosts.filter(
          (post) => post.featured === 1
        );
        // Sort the filtered posts based on their dates in descending order
        filteredFeaturedPosts.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        // Take only the first post from the sorted list
        const latestFeaturedPost = filteredFeaturedPosts.length
          ? [filteredFeaturedPosts[0]]
          : [];
        setFeaturedPost(latestFeaturedPost);
      } catch (err) {
        console.error(err);
      }
    };
    fetchFeaturedPosts();
  }, []);

  const estimateReadingTime = (text) => {
    // Assuming average reading speed of 200 words per minute
    const wordsPerMinute = 200;
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  };

  const getText = (html, maxLength = 150) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let textContent = doc.body.textContent.trim();
    if (textContent.length > maxLength) {
      textContent = textContent.substring(0, maxLength) + "...";
    }
    return textContent;
  };

  return (
    <div className="blog_section margin_top mb-5">
      {featuredPost.map((post) => (
        <div className="container" key={post.id}>
          {/* FEATURED ARTICLE SEC */}
          <div className="mb-2 d-md-none d-block text-center">
            <p
              className="blog_type_text featured_article_text fs-2 p-2"
            >
              Featured Article
            </p>
          </div>

          <Link
            to={`/blogcontent/${post.id}`}
            className="text-decoration-none text-black"
          >

            <h5
              className="card-title hero_subtitle card_title d-md-none d-block"
            >
              {post.title}
            </h5>
          </Link>

          <div>
            <p
              className="blog_type_text blogs_page_subtext mt-3 d-md-none d-block"
            >
              {moment(post.date).fromNow()}
            </p>
          </div>

          <div className="d-lg-flex gap-5 featured_block_gap my-md-5">
            <Link to={`/blogcontent/${post.id}`}>
              <div>

                <img
                  src={post.img}
                  width={"580px"}
                  height={"435px"}
                  alt={`Blog ${post.title} Cover image`}
                  className="blog_img_size"

                />
              </div>
            </Link>
            <div className="subscribe_block">
              <div className="card-body rounded shadow py-md-4 px-md-5 p-3">
                <div className="featured_article mb-4 d-none d-md-block">


                  <p
                    className="blog_type_text featured_article_text p-2"
                  >
                    Featured Article
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex gap-3">


                    <p
                      className="blog_type_text blogs_page_subtext"
                    >
                      Product
                    </p>
                    <span className="filled_circle_blog bg-primary"></span>

                    <p
                      className="blog_type_text blogs_page_subtext"
                    >
                      {post.cat}
                    </p>
                  </div>

                  <div>
                    <p
                      className="blog_type_text blogs_page_subtext"
                    >
                      {estimateReadingTime(getText(post.desc))} Min Read
                    </p>
                  </div>
                </div>
                <Link
                  to={`/blogcontent/${post.id}`}
                  className="text-decoration-none text-black"
                >

                  <h5
                    className="card-title hero_subtitle card_title d-none d-md-block"
                  >
                    {post.title}
                  </h5>
                </Link>
                <Link
                  to={`/blogcontent/${post.id}`}
                  className="text-decoration-none text-black"
                >

                  <p
                    className="card-text footer_subtext card_text mt-2 mb-md-5"
                  >
                    {getText(post.desc)}
                  </p>
                </Link>
                <div className="d-flex justify-content-between pt-3">
                  <div>
                    <Link
                      className="blog_type_text blogs_page_subtext text-decoration-none"
                      to={`/blogcontent/${post.id}`}
                    >
                      Read More &nbsp; &gt;
                    </Link>
                  </div>

                  <div>
                    <p
                      className="blog_type_text blogs_page_subtext d-none d-md-block"
                    >
                      {moment(post.date).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Featured;
