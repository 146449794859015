import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Base_URL } from "constant/Constant";
import CountrySection from "../country/countrySection";

const Blogs = () => {

  const [posts, setPosts] = useState([]);

  const cat = useLocation().search

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts`, {
          params: { cat: cat } // Include the category as a query parameter if needed
        });
        // Sort the posts based on the date in descending order and take the first 3 posts
        const sortedPosts = res.data.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3);
        setPosts(sortedPosts);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const getText = (html, maxLength = 150) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let textContent = doc.body.textContent.trim();
    if (textContent.length > maxLength) {
      textContent = textContent.substring(0, maxLength) + '...';
    }
    return textContent;
  };

  return (
    <div className="blog_section margin_top mb-5">
      <div className="container">
        <div>
          <h3
            className="blog_title sub_title mt-5 mb-2"
          >
            Tago <span style={{ color: '#0581f8' }}>Blogs</span> & News
          </h3>
        </div>

        {/* OTHER BLOGS */}
        <div className="blog-cards-container d-md-flex justify-center mb-4 mt-4">
          {posts.map((post, index) => (
            <NavLink className="card mb-4 mb-md-0 shadow border cards" key={index} to={`/blogcontent/${post.id}`} style={{
              width: "33.33 %",
              padding: "10px"
            }}>
              <div style={{
                height: "300px",

              }}>
                <img
                  src={post.img}
                  style={{
                    height: "100%",
                    objectFit: " cover"
                  }}
                  alt={`Blog ${post.title} Cover image`}
                  className="card-img-top p-2"
                />
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between gap-2">
                  <div className="d-flex gap-3">
                    <p className="blog_type_text">
                      Product
                    </p>
                    <span className="filled_circle"></span>
                    <p className="blog_type_text">
                      {post.cat}
                    </p>
                  </div>
                  <div>
                    <p className="blog_type_text">
                      {moment(post.date).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="text-decoration-none text-black">
                  <h5 className="card-title download_app_subtext text-black question_blog fw-semibold">
                    {post.title}
                  </h5>
                </div>
                <div className="text-decoration-none text-black" >
                  <p className="card-text footer_subtext pb-2">
                    {getText(post.desc)}
                  </p>
                </div>
              </div>
            </NavLink>
          ))}
        </div>

        <div className="text-center">
          <button
            type="button"
            className="btn btn-primary rounded-5 px-3 py-2 mt-md-3 mt-5 mb-4"
            onClick={() => navigate('/blog')}>
            View All Blogs
          </button>
        </div>
        {/* <CountrySection/> */}
      </div>
    </div>
  );
};

export default Blogs;

