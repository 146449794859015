import React, { useEffect, useRef } from 'react';
import Amlbc from '../../../assets/images/partners/amlbc.png'
import Circle from '../../../assets/images/partners/circle.png'
import Cybrid from '../../../assets/images/partners/cybrid.png'
import FlutterWave from '../../../assets/images/partners/flutterwave.png'
import Identity from '../../../assets/images/partners/identity.png'
import Moneygram from '../../../assets/images/partners/moneygram.png'
import Stellar from '../../../assets/images/partners/stellar.png'
import Sumsub from '../../../assets/images/partners/sumsub.png'
import Transak from '../../../assets/images/partners/transak.png'
import Yellowcard from '../../../assets/images/partners/yellowcard.png'
import './partners.css';

const BrandPartnersSlider = () => {

  return (
    <section className='partners-section'>
      <div className="logos">
        <div className="logos-slide">
          <img src={Amlbc} />
          <img src={Circle} />
          {/* <img src={Cybrid} /> */}
          <img src={FlutterWave} />
          <img src={Identity} />
          <img src={Stellar} />
          <img src={Sumsub} style={{height: "65px"}} />
          <img src={Transak} style={{height: "65px"}} />
          <img src={Amlbc} />
          <img src={Circle} />
          <img src={FlutterWave} />
        </div>

        {/* <div className="logos-slide">
          <img src={Amlbc} />
          <img src={Circle} />
          <img src={Cybrid} />
          <img src={FlutterWave} />
          <img src={Identity} />
          <img src={Stellar} />
          <img src={Sumsub} />
          <img src={Transak} />
        </div> */}
      </div>
    </section>
  );
};

export default BrandPartnersSlider;
