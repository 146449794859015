import React, { useState } from 'react';
import countriesData from './countryData.json';
import './../../App.css';
import { CountryFinancialOptions } from './countryFinancialOptions';
import CountryItem from "./countryItem";
import { Heading } from '@chakra-ui/react';

const financialOptions = {
    [CountryFinancialOptions.BankDeposit]: 'Bank Deposit',
    [CountryFinancialOptions.BankWithdraw]: 'Bank Withdraw',
    [CountryFinancialOptions.CashDepositWithdraw]: 'Cash Deposit Withdraw',
    [CountryFinancialOptions.DebitCreditCardWithdraw]: 'Debit Credit Card Withdraw',
    [CountryFinancialOptions.DebitCreditCardDeposit]: 'Debit Credit Card Deposit',
    [CountryFinancialOptions.MobileMoney]: 'Mobile Money',
    [CountryFinancialOptions.TagoPayCard]: 'TagoPay Card',
    [CountryFinancialOptions.DigitalWallets]: 'Digital Wallets',
    [CountryFinancialOptions.CryptoDeposit]: 'Crypto Deposit',
    [CountryFinancialOptions.CryptoWithdraw]: 'Crypto Withdraw', 
    [CountryFinancialOptions.AskUs]: 'Ask Us',
    [CountryFinancialOptions.NotAvailable]: 'Not Available'
};

const CountrySection = () => {
    const [visibleCount, setVisibleCount] = useState(30);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showMore = () => {
        setVisibleCount(prevCount => prevCount + 30);
    };

    const getFinancialOptionsArray = (options: number[]): string[] => {
        return options.map(option => financialOptions[option as keyof typeof financialOptions] || 'Unknown Option');
    };

    const handleSelectChange = (option: number) => {
        setSelectedOptions(prevOptions => {
            if (prevOptions.includes(option)) {
                return prevOptions.filter(o => o !== option);
            } else {
                return [...prevOptions, option];
            }
        });
    };

    const filteredCountries = countriesData.countries.filter(country => {
        const matchesSearchTerm = country.countryName!.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFinancialOptions = selectedOptions.length === 0 || selectedOptions.some(option => country.countryFinancialOptions!.includes(option));
        return matchesSearchTerm && matchesFinancialOptions;
    });

    return (
        <div style={{background:'lightblue'}}>
        <Heading
                            fontSize={{ base: '26px', md: '40px' }}
                            my="16px"
                            fontWeight={800}
                            textAlign="center"
                        >Tago<span style={{ color: '#0581f8' }}>Cash</span> Jurisdictions & <span style={{ color: '#0581f8' }}>Methods</span> </Heading>
            <div className="searchFilterContainer">
                <input
                    type="text"
                    placeholder="Search by country name"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="searchInput"
                />
                <div
                    className={`filterSelect ${dropdownOpen ? 'active' : ''}`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                    {selectedOptions.length > 0
                        ? getFinancialOptionsArray(selectedOptions).join(', ')
                        : 'Select Financial Options'}
                    <ul>
                        {Object.entries(financialOptions).map(([key, value]) => (
                            <li key={key} onClick={() => handleSelectChange(Number(key))}>
                                {selectedOptions.includes(Number(key)) ? '✔ ' : ''}{value}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="countryContainer">
                {filteredCountries.slice(0, visibleCount).map((country, index) => (
                    <div key={index}>
                        <CountryItem
                            code={country.code}
                            countryName={country.countryName}
                            financialOptions={getFinancialOptionsArray(country.countryFinancialOptions!)}
                        />
                    </div>
                ))}
            </div>
            {visibleCount < filteredCountries.length && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className="btn btn-primary rounded-5 px-3 py-2 mt-md-3 mt-5 mb-4" onClick={showMore}>Show More</button>
                </div>
            )}
        </div>
    );
};

export default CountrySection;
