import React, { useState, useEffect } from 'react'
import axios from "axios";
import { NavLink, useNavigate } from 'react-router-dom';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import ipinfo from 'ipinfo';
import { PaymentProcess } from '../../components/home/process'
import { Better } from '../../components/home/better'
import FasstSection from '../../components/home/FasstSection'
import Blog from '../../components/home/BlogSection'
import DownloadApp from '../../components/home/DownloadApp'
import Hero from '../../components/home/HeroSection'
import NewsLetter from '../../components/NewsLetterSection'
import Partners from '../../components/home/partners/partners'
import {
    Box,
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Text,
    Link,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import LinesImg from 'assets/images/lines-img.png'
import Lines2Img from 'assets/images/lines2-img.png'
import iphone from 'assets/images/iphone.png'
import ProductBg from 'assets/images/product-bg.png'
import PhoneBg from 'assets/images/phone-img.png'
import PeopleBg from 'assets/images/people.png'
import howitworks from 'assets/images/howitwork.png'
import Phone from 'assets/images/phoneone.png'
import Phonetwo from 'assets/images/phonetwo.png'
import howItsWorks from 'assets/images/howitworkpic.png'
import {
    AppleIcon,
    Cash,
    Dollar,
    PlayIcon,
    Shield,
    Wallet
} from 'assets/icons'
import phoneScreen from 'assets/images/phone-screen.png'
import redLogo from 'assets/images/tago_logos/redlogo.png'
import blueLogo from 'assets/images/tago_logos/bluelogo.png'
import greenLogo from 'assets/images/tago_logos/greenlogo.png'
import purpleLogo from 'assets/images/tago_logos/purplelogo.png'
import circles from 'assets/images/circles.png'
import Mobile2 from 'assets/images/mobile2.png'
import { IoFlash } from "react-icons/io5";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { GrSecure } from "react-icons/gr";
import { IoMdDownload } from "react-icons/io";
import { CiBank } from "react-icons/ci";
import { SiMoneygram } from "react-icons/si";
import { BsFillWalletFill } from "react-icons/bs";
import { BsCashCoin } from "react-icons/bs";
import Lottie from "react-lottie";
import Slider from 'react-slick'
import './style.css'
import Phonetrans from '../../assets/images/lottieFiles/Phone (4).json'
import Arrow from '../../assets/images/lottieFiles/arrow.json'
import { Base_URL } from 'constant/Constant';
import Tagocash from "../../assets/images/lottieFiles/tago-symbol-lotie2.json";
import Tagocash2 from "../../assets/images/lottieFiles/tagolotie2.0 + text 2.json";
import Cash3usdc from "../../assets/images/lottieFiles/usdc+tagocash.json";
import CountrySection from 'components/country/countrySection';

const textArray = ["GLOBAL CURRENCY", "PAY ANYONE INSTANTLY !", "TRANSPARENT FEE STRUCTURE"];

interface HomePage {
    cover: string;
    img1: string;
    img2: string;
}

export default function Landing() {
    const navigate = useNavigate();

    const globallyOptions = {
        loop: true,
        autoplay: true,
        animationData: Cash3usdc,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const globalyOptions = {
        loop: true,
        autoplay: true,
        animationData: Tagocash,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // const handleApp = () => {
    //     navigate('/wallet');
    //     window.scrollTo(0, 0); // Scroll to the top of the page
    // };

    const scrollToTopAndNavigate = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

    const settings = {
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        afterChange: (index: number) => {
            setActiveSlideIndex(index)
        },
    }

    const globeOptions = {
        loop: true,
        autoplay: true,
        animationData: Arrow,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const phoneOptions = {
        loop: true,
        autoplay: true,
        animationData: Phonetrans,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const contactOptions = {
        loop: true,
        autoplay: true,
        animationData: Tagocash2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // FUNCTION TO COLLECT VISITOR DETAILS WHEN THEY VISIT THE WEBSITE
    // useEffect(() => {
    //     const collectVisitorDetails = async () => {
    //         try {
    //             const hasVisitorDetailsCookie = document.cookie.includes('visitorDetails=true');
    //             if (hasVisitorDetailsCookie) {
    //                 return;
    //             }

    //             const hasAcceptedCookie = document.cookie.includes('acceptedCookie=true');
    //             if (!hasAcceptedCookie) {
    //                 // If the acceptedCookie is not present, you can return or take appropriate action.
    //                 return;
    //             }

    //             const ipResponse = await axios.get('https://ipinfo.io?token=977e06d01e2778');
    //             console.log(ipResponse, "landing")

    //             const ipDetails = ipResponse.data;
    //             const timestamp = new Date();

    //             const details = {
    //                 ip: ipDetails.ip,
    //                 country: ipDetails.country,
    //                 city: ipDetails.city,
    //                 latitude: ipDetails.loc.split(',')[0],
    //                 longitude: ipDetails.loc.split(',')[1],
    //                 browser: navigator.userAgent,
    //                 device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : isBrowser ? 'Browser' : 'Other',
    //                 timestamp: timestamp.toISOString(),
    //             };

    //             // Send details to your backend for storage

    //             await axios.post(`${Base_URL}/collectVisitorDetails`, details);

    //             // Set a cookie to indicate that visitor details have been collected
    //             document.cookie = 'visitorDetails=true; max-age=' + (365 * 24 * 60 * 60); // Expires in 1 year
    //             console.log('Visitor details collected and sent to the backend');

    //         } catch (error) {
    //             console.error('Error collecting and sending visitor details:', error);

    //         }
    //     };
    //     collectVisitorDetails();
    // }, []);

    const [visitorCount, setVisitorCount] = useState(0);

    // useEffect(() => {
    //     // Fetch the visitor count from the backend

    //     axios.get(`${Base_URL}/visitor-count`)
    //         .then(response => {
    //             setVisitorCount(response.data.count);
    //             // Increment the visitor count on the backend
    //             axios.post(`${Base_URL}/increment-visitor`)

    //                 .then(() => {
    //                     console.log('Visitor count incremented on the backend');
    //                 })
    //                 .catch(error => {
    //                     console.error('Error incrementing visitor count on the backend:', error);
    //                 });
    //         })
    //         .catch(error => {
    //             console.error('Error fetching visitor count:', error);
    //         });
    // }, []);


    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAnimation('slide-out');
            setTimeout(() => {
                setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
                setAnimation('slide-in');
            }, 1000); // Delay for the slide-out animation to complete
        }, 2000); // Rotate every 2 seconds (adjust as needed)

        return () => clearInterval(intervalId); // Cleanup the interval
    }, []);

    const [homePage, setHomePage] = useState<HomePage[]>([]);
    console.log(homePage, "landing page>>>")

    useEffect(() => {
        const fetchHomePage = async () => {
            try {
                const res = await axios.get<HomePage[]>(`${Base_URL}/home_page`);
                setHomePage(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchHomePage();
    }, []);



    return (
        <>
            {/* START OF SECTION ONE */}
            <Hero />
            {/* END OF SECTION ONE */}

            {/* START OF SECTION TWO */}
            <Box
                as="section"
                transform="matrix(1, -0.12, 0, 1, 0, 0)"
                marginTop={{ base: "120px", sm: "140px" }}
                lineHeight={{ lg: "3.5rem" }}
                className="second_sec_text"
            >
                <div>
                    <Image
                        position="absolute"
                        display="inline"
                        src={LinesImg}
                        alt="LinesImg"
                        top={{ base: '-45px', md: '-90px' }}
                        left="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        background="darkBlue"
                        height={{ base: "1500px", sm: "1200px", md: "1300px", lg: "1150px" }}
                        pt={{ base: '60px', md: '100px' }}
                        position="relative"
                        zIndex={1}
                    >
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            textAlign="center"
                        >
                            <Box height="140px">
                                <Heading
                                    color="primary.500"
                                    mb="1.3rem"
                                    fontWeight="900"
                                    margin="0"
                                    css={{
                                        '@media (min-width: 1400px)': {
                                            fontSize: "4.6rem"
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1400px)': {
                                            fontSize: "3.7rem"
                                        },
                                        '@media (min-width: 992px) and (max-width: 1200px)': {
                                            fontSize: "3.5rem"
                                        },
                                        '@media (min-width: 768px) and (max-width: 992px)': {
                                            fontSize: "3rem"
                                        },
                                        '@media (min-width: 576px) and (max-width: 768px)': {
                                            fontSize: "3rem"
                                        },
                                        '@media (max-width: 576px)': {
                                            fontSize: "2.5rem"
                                        },
                                    }}

                                >
                                    {textArray[currentTextIndex]}
                                </Heading>
                            </Box>
                            {homePage.map((page, index) => (
                                <div key={index}>
                                    <Image
                                        display="inline"
                                        src={`${Base_URL}/images/${page.cover}`}
                                        alt="PhoneImg"
                                        width="70%"
                                    />
                                </div>
                            ))}
                            <Flex
                                flexWrap="wrap"
                                justify="center"
                                gap="30px"
                                marginTop={{ base: "1rem", sm: "3rem" }}
                            >
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Fast:'
                                    icon={<IoFlash />}
                                    description='Transactions are completed in less than 10 seconds.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Transparent:'
                                    icon={<AiFillSafetyCertificate />}
                                    description='You pay what you see. No hidden fees.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Secure:'
                                    icon={<GrSecure />}
                                    description='Cryptographic security with biometric authentication.  Only you can access your money.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Modern:'
                                    icon={<IoMdDownload />}
                                    description='Simple and easy to use'
                                />
                            </Flex>
                        </Container>
                    </Box>
                </div>
            </Box>
            {/* END OF SECTION TWO */}

            {/* START OF SECTION THREE */}
            <Better />
            {/* END OF SECTION THREE */}

            {/* START OF SECTION FOUR */}
            <div>
                <Box
                    as="section"
                    transform="matrix(1, -0.12, 0, 1, 0, 0)"
                    marginTop={{ base: "120px", sm: "140px" }}
                    lineHeight={{ lg: "3.5rem" }}
                    className="second_sec_text"
                >
                    <Image
                        position="absolute"
                        display="inline"
                        src={LinesImg}
                        alt="LinesImg"
                        top={{ base: '-45px', md: '-90px' }}
                        left="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        background="darkBlue"
                        pt={{ base: '60px', md: '70px' }}
                        // height={{ base: "450px", sm: "600px", md: "800px", lg: "600px" }}
                        position="relative"
                        zIndex={1}
                        css={{
                            '@media (min-width: 1400px)': {
                                height: "600px"
                            },
                            '@media (min-width: 1200px) and (max-width: 1400px)': {
                                height: "600px"
                            },
                            '@media (min-width: 992px) and (max-width: 1200px)': {
                                height: "550px"
                            },
                            '@media (min-width: 768px) and (max-width: 992px)': {
                                height: "780px"
                            },
                            '@media (min-width: 576px) and (max-width: 768px)': {
                                height: "700px"
                            },
                            '@media (min-width: 450px) and (max-width: 576px)': {
                                height: "500px"
                            },
                            '@media (min-width: 350px) and (max-width: 450px)': {
                                height: "450px"
                            },
                        }}
                    >
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            textAlign="center"
                        >
                            <Box
                                justifyContent="space-between"
                                alignItems={{ md: 'center', lg: "center" }}
                                display={{ md: 'block', lg: 'flex' }}
                                textAlign={{ base: 'center', lg: 'unset' }}
                                marginTop={{ lg: "30px", xl: "30px" }}
                            >
                                <Box>
                                    <Box display={{ base: "flex", sm: "unset" }} justifyContent={{ base: "center", sm: "unset" }}>
                                        <Box css={{
                                            '@media (min-width: 1400px)': {
                                                width: "600px"
                                            },
                                            '@media (min-width: 1200px) and (max-width: 1400px)': {
                                                width: "600px"
                                            },
                                            '@media (min-width: 992px) and (max-width: 1200px)': {
                                                width: "550px"
                                            },
                                            '@media (min-width: 768px) and (max-width: 992px)': {
                                                width: "100%"
                                            },
                                            '@media (min-width: 576px) and (max-width: 768px)': {
                                                width: "100%"
                                            },
                                            '@media (max-width: 576px)': {
                                                width: "100%"
                                            },
                                        }}>
                                            <Text
                                                fontWeight={900}
                                                className="bold-text-style"
                                                textAlign="left"
                                                fontFamily="Roboto, sans-serif"
                                                color="#23A6F0"
                                                margin="0"
                                                css={{
                                                    '@media (min-width: 1400px)': {
                                                        fontSize: "60px",
                                                        lineHeight: "4rem"
                                                    },
                                                    '@media (min-width: 1200px) and (max-width: 1400px)': {
                                                        fontSize: "50px",
                                                        textAlign: "center",
                                                        lineHeight: "3.5rem"
                                                    },
                                                    '@media (min-width: 992px) and (max-width: 1200px)': {
                                                        fontSize: "45px",
                                                        textAlign: "center",
                                                        lineHeight: "3.5rem"
                                                    },
                                                    '@media (min-width: 768px) and (max-width: 992px)': {
                                                        fontSize: "50px",
                                                        textAlign: "center",
                                                        lineHeight: "3.5rem"
                                                    },
                                                    '@media (min-width: 576px) and (max-width: 768px)': {
                                                        fontSize: "40px",
                                                        textAlign: "center",
                                                        lineHeight: "3.5rem"
                                                    },
                                                    '@media (max-width: 576px)': {
                                                        fontSize: "2.3rem",
                                                        textAlign: "center",
                                                        lineHeight: "2.5rem"
                                                    },
                                                }}
                                            >
                                                Send
                                                <span style={{ color: "#23A6F0", paddingLeft: "9px", paddingRight: "9px" }}>
                                                    Cash
                                                </span>
                                                To Anyone
                                            </Text>
                                            <Text fontWeight={700} fontFamily="Roboto, sans-serif" textAlign="center" color="#23A6F0"
                                                css={{
                                                    '@media (min-width: 1400px)': {
                                                        fontSize: "20px"
                                                    },
                                                    '@media (min-width: 1200px) and (max-width:1400px)': {
                                                        fontSize: "20px"
                                                    },
                                                    '@media (min-width: 992px) and (max-width:1200px)': {
                                                        fontSize: "17px"
                                                    },
                                                    '@media (min-width: 768px) and (max-width:992px)': {
                                                        fontSize: "18px"
                                                    },
                                                    '@media (min-width: 576px) and (max-width:768px)': {
                                                        fontSize: "16px"
                                                    },
                                                    '@media (max-width: 576px)': {
                                                        fontSize: "10px",
                                                        paddingTop: "2px"
                                                    }
                                                }}
                                            >
                                                Your partner receives <span style={{ color: '#0581f8' }}>EXACTLY</span> what you sent.
                                            </Text>
                                        </Box>
                                    </Box>


                                </Box>
                                <Lottie options={phoneOptions} />
                                {/* <Flex
                                    justifyContent={{ base: "center", md: "center", lg: "flex-end" }}
                                    alignItems="center"
                                >
                                    <Image
                                        src={Phone}
                                        alt="howitworks"
                                        maxW={{ base: '100px', sm: "150px", md: '250px', lg: '170px', xl: "200px" }}
                                        w="100%"
                                    />
                                    <Box
                                        style={{ display: "flex", alignItems: "center" }}
                                        flexDirection="column"
                                    >
                                        <Box
                                            maxW={{ base: "50px", sm: "100px", md: "150px", lg: "140px", xl: "180px" }}
                                            maxH={{ base: "80px", sm: "100px", md: "150px", lg: "140px", xl: "180px" }}
                                        >
                                            <Lottie options={globeOptions} />
                                        </Box>
                                        <Text
                                            textAlign={{ base: 'center', md: 'center', lg: "center" }}
                                            fontSize={{ base: '8px', md: '20px' }}
                                            fontWeight="700"
                                            marginBottom="0px"
                                            fontFamily="Roboto, sans-serif"
                                            color="#23A6F0"
                                        >
                                            Peer-to-Peer <br /> digital <br /> currency <br /> transfer{' '}
                                        </Text>
                                    </Box>
                                    <Image
                                        src={Phonetwo}
                                        alt="howitworks"
                                        maxW={{ base: '100px', sm: "150px", md: '250px', lg: '170px', xl: "200px" }}
                                        w="100%"
                                    />
                                </Flex> */}
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </div >
            {/* END OF SECTION FOUR */}

            {/* START OF SECTION FIVE*/}
            <div>
                <Container maxW="1280px" textAlign="center" marginTop={{ base: "50px", sm: "150px" }}>
                    <Box display={{ base: "flex", sm: "unset" }} justifyContent={{ base: "center", sm: "unset" }}>
                        <Box width={{ base: "93%", sm: "unset" }}>
                            <Heading
                                my="20px"
                                fontSize={{ base: '2.3rem', md: '50px' }}
                                fontFamily="Roboto, sans-serif"
                                fontWeight={900}
                            >
                                Why Use Tago<span style={{ color: "#2E3293" }}>Cash </span>as Remittance?
                            </Heading>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Box width={{ base: "100%", md: "60%", lg: "40%" }}>
                            <Lottie options={globallyOptions} />
                        </Box>
                    </Box>

                    <Flex
                        flexWrap="wrap"
                        justify="center"
                        gap="30px"
                        mt="2rem"
                        mb="5rem"
                    >
                        <Experience
                            title='Fast'
                            icon={<IoFlash />}
                            description='Send money in seconds to your loved ones.'
                        />
                        <Experience
                            title='Secure'
                            icon={<GrSecure />}
                            description='Biometric authentication & cryptographic security'
                        />
                        <Experience
                            title='Free'
                            icon={<IoMdDownload />}
                            description='Free wallet to wallet transfer.'
                        />
                        <Experience
                            title='Transparent'
                            icon={<AiFillSafetyCertificate />}
                            description='Any transaction with cost is fully disclosed'
                        />
                    </Flex>
                </Container>
            </div >
            {/* END OF SECTION FIVE */}

            {/* START OF SECTION SIX */}
            <div style={{
                marginTop: '5em'
            }}>
                <Box
                    as="section"
                    transform="matrix(1, -0.12, 0, 1, 0, 0)"
                    marginTop={{ base: "120px", sm: "140px" }}
                    lineHeight={{ lg: "3.5rem" }}
                    className="second_sec_text"
                >
                    <Image
                        position="absolute"
                        display="inline"
                        src={LinesImg}
                        alt="LinesImg"
                        top={{ base: '-45px', md: '-90px' }}
                        left="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        background="darkBlue"
                        pt={{ base: '60px', md: '70px' }}
                        // height={{ base: "100%", sm: "950px", md: "1150px", lg: "700px" }}
                        position="relative"
                        zIndex={1}
                        css={{
                            '@media (min-width: 1400px)': {
                                height: "680px"
                            },
                            '@media (min-width: 1200px) and (max-width: 1400px)': {
                                height: "650px"
                            },
                            '@media (min-width: 992px) and (max-width: 1200px)': {
                                height: "940px"
                            },
                            '@media (min-width: 768px) and (max-width: 992px)': {
                                height: "940px"
                            },
                            '@media (min-width: 576px) and (max-width: 768px)': {
                                height: "890px"
                            },
                            '@media (min-width: 525px) and (max-width: 576px)': {
                                height: "890px"
                            },
                            '@media (min-width: 360px) and (max-width: 525px)': {
                                height: "1450px"
                            },
                            '@media (min-width: 300px) and (max-width: 360px)': {
                                height: "1500px"
                            },
                        }}
                    >
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            textAlign="center"
                        >
                            <FasstSection />
                        </Container>
                    </Box>
                </Box>
            </div>
            {/* END OF SECTION SIX*/}

            {/* START OF SECTION SEVEN */}
            <div>
                <Container maxW="1280px" mb={{ base: '2em', md: '8rem' }} mt={{ base: "2em", sm: "5em" }}>
                    <Box display={{ base: "flex", sm: "unset" }} justifyContent={{ base: "center", sm: "unset" }}>
                        <Box width={{ base: "100%", sm: "unset" }}>
                            <Heading
                                fontSize={{ base: '2.3rem', md: '50px', lg: '60px' }}
                                mb="20px"
                                fontWeight={900}
                                textAlign="center"
                                fontFamily="Roboto, sans-serif"
                            >
                                Get More With Tago<span style={{ color: '#2E3293' }}>Cash</span>
                            </Heading>
                        </Box>
                    </Box>
                    <Flex flexWrap="wrap" gap="24px" mb="30px" justify="center">
                        <WhatWeOffer
                            title='Bank Deposit & Withdrawal'
                            icon={<CiBank />}
                            description='Link bank account to your wallet for immediate deposit & withdrawl.'
                        />
                        <WhatWeOffer
                            title='Withdraw or deposit currency from 21,000+ store'
                            icon={<SiMoneygram />}
                            description='Withdraw or top up your wallet from any MoneyGram store.'
                        />
                        <WhatWeOffer
                            title='Global Availability'
                            icon={<BsFillWalletFill />}
                            description='Use any of our global partners to deposit or withdraw cash'
                        />
                        <WhatWeOffer
                            title='Bank or Mobile Money Transfer'
                            icon={<BsCashCoin />}
                            description='Withdraw or deposit cash via bank transfer or via mobile money.'
                        />
                    </Flex>
                </Container>
            </div >
            {/* END OF SECTION SEVEN */}

            {/* START OF  SECTION EIGHT */}
            <Box
                as="section"
                transform="matrix(1, -0.12, 0, 1, 0, 0)"
                position="relative"
                marginTop={{ base: "3.5em", sm: "unset" }}
            >
                <div >
                    <Image
                        position="absolute"
                        display="inline"
                        src={Lines2Img}
                        alt="LinesImg"
                        bottom={{ base: '-40px', md: '-70px' }}
                        right="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        backgroundColor="darkBlue"
                        pb={{ base: '40px', md: '130px' }}
                        pt={{ base: '80px', md: '0px' }}
                        position="relative"
                        zIndex={1}
                        bg={`#0a2540 url(${ProductBg}) no-repeat`}
                        bgPosition={{ base: '0px 700px', md: '0px 200px' }}
                        bgSize={{ base: '60%', md: 'auto' }}
                    >
                        <Box
                            position="absolute"
                            left="43%"
                            top="33%"
                            width="13%"
                            transform={{ base: 'translate(50%, 50%)', sm: 'none' }}
                            css={{
                                '@media (min-width: 1200px) and (max-width: 1400px)': {
                                    left: '50%',
                                    top: '45%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '15%',
                                },
                                '@media (max-width: 960px)': {
                                    left: '56%',
                                    top: '42%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '15%'
                                },
                                '@media (max-width: 910px)': {
                                    left: '55%',
                                    top: '31%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '15%'
                                },
                                '@media (max-width: 850px)': {
                                    left: '55%',
                                    top: '31%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '15%'
                                },
                                '@media (max-width: 767px)': {
                                    left: '50%',
                                    top: '36%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '47%',
                                },
                                '@media (max-width: 600px)': {
                                    left: '50%',
                                    top: '38%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '60%',
                                },
                                '@media (max-width: 500px)': {
                                    left: '50%',
                                    top: '27%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '65%',
                                },
                            }}
                        >
                            <Image
                                src={PeopleBg}
                                alt=""
                                width="100%"
                                borderWidth="80px"
                                borderColor="black"
                            />
                        </Box>
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            overflow="hidden"
                        >
                            <Flex
                                align="center"
                                justifyContent="center"
                                display={{ base: 'block', md: 'flex' }}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                <Box
                                    maxWidth={{ sm: "100%", md: "482px" }}
                                    maxW="482px"
                                    w="100%"
                                >
                                    <Text
                                        variant="white"
                                        fontSize={{ base: '2.3rem', md: '60px' }}
                                        my="10px"
                                        fontWeight={{ base: "700" }}
                                        fontFamily="Roboto, sans-serif"
                                    >
                                        <span style={{ color: "#2E3293", paddingRight: "9px" }}>
                                            Cash
                                        </span>
                                        Wallet or Remittance App?
                                    </Text>
                                    <Text variant="white" fontFamily="Roboto, sans-serif">
                                        When given a choice, take both
                                    </Text>
                                </Box>
                                <Box position="relative" ml={{ base: 0, md: '40px' }}>
                                    <Box
                                        background="radial-gradient(50% 50% at 50% 50%, #599CFF 0%, rgba(57, 184, 255, 0) 100%)"
                                        opacity="0.4"
                                        filter="blur(2px)"
                                        transform="matrix(1, 0.02, 0, 1, 0, 0)"
                                        width="94%"
                                        height="95%"
                                        position="absolute"
                                        top="-100px"
                                        left="8%"
                                        zIndex="-1"
                                    ></Box>
                                    {homePage.map((page, index) => (
                                        <div key={index}>
                                            <Image
                                                src={`${Base_URL}/images/${page.img1}`}
                                                alt="PhoneImg"
                                                width={{ base: "200px", lg: "250px" }}
                                            />
                                        </div>
                                    ))}
                                    {/* <Image
                                        src={iphone}
                                        alt="PhoneImg"
                                        width={{ base: "200px", lg: "250px" }}
                                    /> */}
                                </Box>
                            </Flex>
                            <HStack
                                marginTop={{ base: "20px", md: "60px", lg: "60px" }}
                                justify="center"
                                align="flex-start"
                                flexWrap="wrap"
                                gap="25px"
                            >
                                <Services
                                    icon={<Dollar size="40" color="white" />}
                                    title='60+ Currencies'
                                    description='Instantly convert $ to 60+ currencies.'
                                // onClick={handleApp}
                                />
                                <Services
                                    icon={<Cash size="40" color="white" />}
                                    title='Easy & Free Transfers'
                                    description='Instantly send money to family, friends, or business partners.'
                                // onClick={handleApp}
                                />

                                <Services
                                    icon={<Shield size="40" color="white" />}
                                    title='Fast & Secure Services'
                                    description='Best in class security with Multisig Verification'
                                // onClick={handleApp}
                                />

                                <Services
                                    icon={<Wallet size="40" color="white" />}
                                    title='Wallet Top - Up'
                                    description='Seamlessly transfer money between accounts and wallet.'
                                // onClick={handleApp}
                                />
                            </HStack>
                        </Container>
                    </Box>
                </div >
            </Box>
            {/* END OF SECTION EIGHT */}

            {/* START OF SECTION NINE */}
            <div style={{ paddingTop: "100px" }}>
                <Box textAlign="center" py="60px">
                    <Container maxW="1280px">
                        <Text
                            color="primary.500"
                            fontSize={{ base: '16px', md: '20px' }}
                            textAlign="center"
                        >
                            Ready to get started?
                        </Text>
                        <Heading
                            fontSize={{ base: '26px', md: '40px' }}
                            my="16px"
                            fontWeight={800}
                            textAlign="center"
                        >
                            Get Tago<span style={{ color: "#2E3293" }}>Cash</span>  And Never Pay To Send Money
                        </Heading>
                        <Box display="flex" justifyContent="center">
                            <Box width={{ base: "60%", sm: "40%", md: "30%", lg: "20%" }}>
                                <Lottie options={globalyOptions} />
                            </Box>
                        </Box>
                        <NavLink to="/signup" onClick={scrollToTopAndNavigate}>
                            <Button rounded="full" w="262px">
                                Register
                            </Button>
                        </NavLink>
                    </Container>
                </Box>
                <CountrySection/>
                <Box bg="#0A2540" h={{ lg: '457px' }} overflow="hidden">
                    <Container maxW="1280px">
                        <Flex
                            align={{ base: 'center', md: 'flex-end' }}
                            justify="space-between"
                            textAlign={{ base: 'center', md: 'left' }}
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Box py={{ base: '20px', md: '96px' }}>
                                <Text
                                    fontSize={{ base: '16px', md: '26px' }}
                                    color="primary.500"
                                >
                                    Our Mobile App
                                </Text>
                                <Heading
                                    fontSize={{ base: '26px', md: '40px' }}
                                    fontWeight="900"
                                    color="white"
                                    mt="21px"
                                    mb="15px"
                                >
                                    Wherever You Go, <span style={{ color: '#0581f8' }}>Tago!</span>
                                </Heading>
                                <Text
                                    fontSize="18px"
                                    fontWeight={400}
                                    color="white"
                                >
                                    Tago<span style={{ color: '#0581f8' }}>Cash</span> Is Your Global Companion
                                </Text>
                                <HStack
                                    gap="16px"
                                    mt="47px"
                                    flexWrap="wrap"
                                    justify={{ base: 'center', md: 'flex-start' }}
                                    w={{ base: '100%', md: 'max-content' }}
                                >
                                    <Box w={{ base: '140px', md: '230px' }}>
                                        <NavLink to="/wallet" onClick={scrollToTopAndNavigate}>
                                            <PlayIcon />
                                        </NavLink>
                                    </Box>
                                    <Box w={{ base: '140px', md: '230px' }}>
                                        <NavLink to="/wallet" onClick={scrollToTopAndNavigate}>
                                            <AppleIcon />
                                        </NavLink>
                                    </Box>
                                </HStack>
                                <Text
                                    fontSize="18px"
                                    fontWeight={400}
                                    color="white"
                                    paddingTop='2em'
                                    margin="0"
                                >
                                    Don&apos;t Leave Home Without It!
                                </Text>
                            </Box>
                            <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                                {homePage.map((page, index) => (
                                    <div key={index}>
                                        <Image
                                            position="relative"
                                            zIndex="1"
                                            src={`${Base_URL}/images/${page.img2}`}
                                            alt="PhoneImg"
                                            width={{ base: "180px", sm: "unset" }}
                                        />
                                    </div>
                                ))}
                                {/* <Image
                                    position="relative"
                                    zIndex="1"
                                    src={Mobile2}
                                    alt="PhoneImg"
                                    width={{ base: "180px", sm: "unset" }}
                                /> */}
                                <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="2">
                                    <Box width="100%">
                                        <Lottie options={contactOptions} />
                                    </Box>
                                </Box>
                            </Box>
                        </Flex>
                    </Container>
                </Box>
            </div >
            {/* END OF SECTION NINE */}

            {/* END OF SECTION TEN */}
            {/* <DownloadApp /> */}
            <Blog />
            <NewsLetter />
            <Partners />
            {/* END OF SECTION TEN */}
        </>
    )
}
function SecureBox({
    icon,
    title,
    pro,
    sub,
    description,
}: {
    icon: JSX.Element
    title: string
    pro: string
    sub: string
    description: string
}) {
    return (
        <Box
            minW="220px"
            maxW="320px"
            bg="white"
            borderRadius="20px"
            boxShadow="sm"
            paddingLeft="48px"
            paddingRight="48px"
            paddingTop="15px"
            paddingBottom="15px"
            lineHeight="1.3"
            textAlign="center"
            flex={1}
            transition="background-color 0.3s ease"
            _hover={{
                bg: '#80E9FF',
                color: "white"
            }}
        >
            <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            <Text fontSize="25px" mt="15px" fontWeight={700} fontFamily="Roboto, sans-serif" color="#636e82">
                {title}
                <span style={{ color: "#2E3293", fontSize: "24px", fontWeight: "700" }}>
                    {pro}
                </span>
                {sub}
            </Text>
            <Text fontSize="15px" mt="10px" fontWeight={400} color="#636e82">
                {description}
            </Text>
        </Box>
    )
}

function Experience({
    icon,
    title,
    description,
}: {
    icon: JSX.Element
    title: string
    description: string
}) {
    return (
        <Box
            minW="220px"
            maxW="320px"
            bg="white"
            borderRadius="20px"
            boxShadow="sm"
            p="40px"
            textAlign="center"
            borderBottom="10px solid #0A2540"
            flex={1}
        >
            <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            <Text fontSize="24px" mt="15px" fontWeight={700}>
                {title}
            </Text>
            <Text fontSize="15px" mt="10px" fontWeight={400}>
                {description}
            </Text>
        </Box>
    )
}

function WhatWeOffer({
    icon,
    title,
    description,
}: {
    icon: JSX.Element
    title: string
    description: string
}) {
    return (
        <Box
            minW="294px"
            maxW="494px"
            borderRadius="12px"
            padding="32px 20px"
            textAlign="center"
            bg="#EBF8FE"
            flex={1}
            _hover={{ bg: 'white' }}
        >
            <Center
                bg="white"
                w="min-content"
                borderRadius="12px"
                width="80px"
                height='80px'
                mx="auto"
            >
                <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            </Center>
            <Heading
                fontSize={{ base: '20px', md: '24px' }}
                mt="24px"
                mb="10px"
            >
                {title}
            </Heading>
            <Text>{description}</Text>
        </Box>
    )
}


function Services({
    icon,
    title,
    description,
    // onClick,
}: {
    icon: JSX.Element
    title: string
    description: string
    // onClick: () => void
}) {
    return (
        <Box textAlign={{ base: 'center', md: 'left' }} minW="200px" flex={1}>
            <Center
                bg="primary.500"
                w="min-content"
                borderRadius="4px"
                p="5px"
                mx={{ base: 'auto', md: '0' }}
                mb="25px"
            // onClick={onClick}
            // cursor="pointer"
            >
                {icon}
            </Center>
            <Text color="white">{title}</Text>
            <Text height='50px'>{description}</Text>
            {/* <Button
                px="0"
                mt="18px"
                variant="ghost"
                rightIcon={<ChevronRightIcon />}
                onClick={onClick}
            >
                Get app Now
            </Button> */}
        </Box>
    )
}