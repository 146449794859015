import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import DOMPurify from "dompurify";
import blue_background from "../../../assets/images/blue_background.png";
import Lottie from "react-lottie";
import walletLottie from "../../../assets/images/lottieFiles/wallet.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from "react-router-dom";
import en from 'i18n-iso-countries/langs/en.json';
import Registration from '../../../assets/images/lottieFiles/Registration.json'
import { Base_URL } from "constant/Constant";
import ReCAPTCHA from "react-google-recaptcha";
import { addEmailsToMailingList } from "utils/sendPulse.utils";
import { toast } from "react-toastify";

countries.registerLocale(en);


const countryList = Object.entries(countries.getNames('en')).map(([code, name]) => ({ code, name }));

const TagoWallet = () => {
  const navigate = useNavigate();

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const [tagoWalletPage, setTagoWalletPage] = useState({});
  useEffect(() => {
    const fetchTagoWalletPage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/tagowallet_page`);
        console.log(res, "tagowallet")
        setTagoWalletPage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchTagoWalletPage();
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userCountry, setUserCountry] = useState('');
  const [formKey, setFormKey] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const [isNameValidone, setIsNameValidone] = useState(true);
  const [isNameValidtwo, setIsNameValidtwo] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCountryNameValid, setIsCountryNameValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isCheckedValid, setIsCheckedValid] = useState(true);

  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [CountryNameError, setCountryNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isCheckedError, setIsCheckedError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isRecaptchaCompleted, setIsRecaptchaCompleted] = useState(false);

  useEffect(() => {
    // Function to fetch user's country based on IP address
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://api.ipbase.com/v1/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setUserCountry(data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchUserCountry(); // Fetch user's country when component mounts
  }, []);

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setIsNameValidone(newFirstName.trim() !== "");
    setFirstNameError(newFirstName.trim() !== "" ? "" : "First Name cannot be empty");
  };


  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setIsNameValidtwo(newLastName.trim() !== "");
    setLastNameError(newLastName.trim() !== "" ? "" : "Last Name cannot be empty");
  };


  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setIsCountryNameValid(selectedCountry !== "");
    setCountryNameError(selectedCountry !== "" ? "" : "Please select a country");
  };

  const handlePhoneChange = (value, country) => {
    const newPhoneNumber = value;
    setPhoneNumber(newPhoneNumber);
    setIsPhoneNumberValid(newPhoneNumber.trim() !== '');
    setPhoneNumberError(newPhoneNumber.trim() !== '' ? '' : 'Phone cannot be empty');
  };

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    setIsCheckedValid(newIsChecked);
    setIsCheckedError(newIsChecked ? "" : "You must agree to the terms");
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setIsRecaptchaCompleted(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Check if all fields are filled and terms are agreed upon
      const isFormValid =
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        email.trim() !== "" &&
        country.trim() !== "" &&
        phoneNumber.trim() !== "" &&
        isChecked;
      recaptchaValue !== null; // Include the check for reCAPTCHA value

      if (!isFormValid) {
        // If form is not valid, set error states and return
        setIsNameValidone(firstName.trim() !== "");
        setIsNameValidtwo(lastName.trim() !== "");
        setIsEmailValid(email.trim() !== "");
        setIsCountryNameValid(country.trim() !== "");
        setIsPhoneNumberValid(phoneNumber.trim() !== "");
        setIsCheckedValid(isChecked);

        setFirstNameError(firstName.trim() !== "" ? "" : "First Name cannot be empty");
        setLastNameError(lastName.trim() !== "" ? "" : "Last Name cannot be empty");
        setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
        setCountryNameError(country !== "" ? "" : "Please select a country");
        setPhoneNumberError(
          phoneNumber.trim() !== "" ? "" : "Phone cannot be empty"
        );
        setIsCheckedError(isChecked ? "" : "You must agree to the terms");

        // Check if reCAPTCHA value is null and set error state if needed
        if (recaptchaValue === null) {
          setRecaptchaError("reCAPTCHA validation failed");
        } else {
          setRecaptchaError("");
        }

        return; // Exit function if form is not valid
      }

      // If form is valid, make the API call
      const data = { firstname: firstName, lastname: lastName, email: email, country: country, phone: phoneNumber };
      axios
        .post(`${Base_URL}/api/posts/postSubcriber`, data)
        .then((res) => {
          // Handle success
          console.log(res.data);
          navigate("/thankyou"); // Navigate to "/thankyou" page
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });

      await addEmailsToMailingList(data)
      // console.log("Form submitted:", {
      //   firstName,
      //   lastName,
      //   email,
      //   country,
      //   phoneNumber,
      //   isChecked,
      // });

      // Clear form fields after successful submission
      setFirstName("");
      setLastName("");
      setEmail("");
      setCountry("");
      setPhoneNumber("");
      setIsChecked(false);
      setIsRecaptchaCompleted(false);
      toast.success('Success!')

    } catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };

  const walletOptions = {
    loop: true,
    autoplay: true,
    animationData: Registration,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="newsletter_page position-relative">
      <div className="d-block d-md-none blue_background">
        <img
          className="position-absolute top-0 left-0"
          src={blue_background}
          width={"85%"}
          height={"275px"}
          alt="blue_back"
        />
      </div>
      <div className="container position-relative pb-5">
        <a href="/" className="text-decoration-none text-black">
          <p className="d-block d-md-none pt-4">&lt; Back</p>
        </a>
        <div className="d-lg-flex py-md-5">
          <div className="img_block margin_top pb-md-5">
            <div className="d-lg-block d-none">
              <Lottie options={walletOptions} height={375} width={425} />
            </div>
          </div>
          <div className="subscribe_block margin_top pb-5">
            <div className="ms-3 mt-3">
              <h3
                className="contact_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tagoWalletPage.title) }}
              >
                {/* Tago<span style={{ color: "#2E3293" }}>Cash </span>
                Pre-Registration */}
              </h3>
              <p className="contact_subtext mb-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tagoWalletPage.desc) }}>
                {/* Be one of the first 100 users to get a bonus $10.00 in your wallet. */}
              </p>
            </div>
            <div className="my-4">
              <input
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}
                className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                id="nameFormControlInput"
                placeholder="First Name *"
                aria-required="true"

              />
              {!isNameValidone && (
                <p className="text-danger pt-2">{firstnameError}</p>
              )}
            </div>
            <div className="my-4">
              <input
                type="text"
                value={lastName}
                onChange={handleLastNameChange}
                className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                id="nameFormControlInput2"
                placeholder="Last Name *"
                aria-required="true"
              />
              {!isNameValidtwo && (
                <p className="text-danger pt-2">{lastnameError}</p>
              )}
            </div>
            <div className="mb-4">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="form-control rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                id="emailFormControlInput"
                placeholder="Enter Email *"
                aria-required="true"
              />
              {!isEmailValid && (
                <p className="text-danger pt-2">{emailError}</p>
              )}
            </div>
            <div className='my-4'>
              <select
                className="form-select rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                value={country}
                onChange={handleCountryChange}
                aria-required="true"
              >
                <option value="">Select Country</option>
                {countryList.map((country) => (
                  <option key={country.code} value={country.code}>
                    <ReactCountryFlag countryCode={country.code.toUpperCase()} svg />
                    {country.name}
                  </option>
                ))}
              </select>
              {!isCountryNameValid && (
                <p className="text-danger pt-2">{CountryNameError}</p>
              )}
            </div>
            <div className="mb-4">
              <PhoneInput
                country={userCountry}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="Phone number"
                className="wallet_input_bg"
              />

              {!isPhoneNumberValid && (
                <p className="text-danger pt-2">{phoneNumberError}</p>
              )}
            </div>
            <div>
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input rounded-0 p-2 me-3 pointer"
                  type="checkbox"
                  name="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                  aria-required="true"
                />
                <label
                  className="form-check-label contact_subtext fs-6"
                  htmlFor="flexCheckDefault"
                >
                  By Subscribing, you agree to the <NavLink to='/terms'><strong>Terms of Service</strong></NavLink>  and you attest that you are 18 Years Old.
                </label>
              </div>
              {!isCheckedValid && (
                <p className="text-danger pt-2">{isCheckedError}</p>
              )}
            </div>
            <div className="mt-4">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={handleRecaptchaChange}
              />
            </div>
            <div className="mt-4">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={
                  !isNameValidone ||
                  !isNameValidtwo ||
                  !isEmailValid ||
                  !isCountryNameValid ||
                  !isPhoneNumberValid ||
                  !isCheckedValid ||
                  !isRecaptchaCompleted
                }
                className="btn button_color text-white rounded-5 px-5 py-2 md-5"
                aria-label="Subscribe to our newsletter"
              >
                Subscribe
              </button>
            </div>
            {/* <p className="text-muted pt-4 m-0">
              <span style={{ color: "#0581F8", fontWeight: "800" }}>NOTE: </span> By Subscribing, you agree to the <NavLink to='/terms'>Terms of Service</NavLink>  and you attest that you are 18 Years Old.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagoWallet;
