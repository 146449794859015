import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Featured from "./featured";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";


const Blogs = () => {

  const [posts, setPosts] = useState([]);

  const cat = useLocation().search

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts`, {
          params: { cat: cat } // Include the category as a query parameter if needed
        });
        // Sort the posts based on the date in descending order (latest first)
        const sortedPosts = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedPosts);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const getText = (html, maxLength = 100) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let textContent = doc.body.textContent.trim();
    if (textContent.length > maxLength) {
      textContent = textContent.substring(0, maxLength) + '...';
    }
    return textContent;
  };

  return (
    <div className="blog_section margin_top ">
      <div className="container">
        <NavLink to="/" className="text-decoration-none text-black">
          <p className="my-4 d-block d-md-none">&lt; Back</p>
        </NavLink>
        <div>

          <h5
            className="hero_title"
          >
            <strong> <span className="hero_title_tago">Tago </span>Blogs</strong>
          </h5>
        </div>

        {/*  FEATURED ARTICLE SEC */}
        <Featured />
        {/* FEATURED BLOG SEC */}

        <div className="text-center mt-5 mb-md-5">
          <p
            className="blog_type_text fs-1"
          >
            All Blogs
          </p>

        </div>


        {/* OTHER BLOGS */}
        <div className=" row g-4 mb-4 mt-4">
          {posts.map((post, index) => (
            <div className="col-md-4" key={index}> 
            <NavLink className="card w-100 h-100 mb-4 mb-md-0 shadow border cards" key={post.index} to={`/blogcontent/${post.id}`} style={{
              width: "33.33 %",
              padding: "10px"
            }}>
              <div style={{
                height: "300px",

              }}>
                <img
                  src={post.img}

                  alt={`Blog ${post.title} Cover image`}
                  className="card-img-top p-2"
                  style={{
                    height: "100%",
                    objectFit: " cover"
                  }}
                />
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between gap-2">
                  <div className="d-flex gap-3">
                    <p
                      className="blog_type_text"
                    >
                      Product
                    </p>
                    {/* <span className="filled_circle"></span> */}
                    <p
                      className="blog_type_text"
                    >
                      {post.cat}
                    </p>
                  </div>

                  <div>
                    <p
                      className="blog_type_text"
                    >
                      {moment(post.date).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="text-decoration-none text-black">
                  <h5
                    className="card-title download_app_subtext text-black question_blog fw-semibold"
                  >
                    {post.title}
                  </h5>
                </div>
                <div className="text-decoration-none text-black">
                  <p
                    className="card-text footer_subtext pb-2"
                  >
                    {getText(post.desc)}
                  </p>
                </div>
              </div>
            </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
