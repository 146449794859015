import React, { useEffect, useState } from "react";
import axios from "axios"
import DOMPurify from 'dompurify'
import news_letter from "../../assets/images/news_letter.png";
import news_letter_mobile from "../../assets/images/news_letter_mobile.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";
import { addEmailsToMailingList } from "utils/sendPulse.utils";
import { toast } from "react-toastify";


const NewsLetterSection = () => {

  const [homePage, setHomePage] = useState({}); // Use object instead of array
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/home_page`);
        setHomePage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);

   // Event handler for email input change
   const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
      // Check if all fields are filled and terms are agreed upon
      const isFormValid =
        email.trim() !== "";
  
      if (!isFormValid) {
        // If form is not valid, set error states and return
        setIsEmailValid(email.trim() !== "");
        setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
        return; // Exit function if form is not valid
      }

    // Perform API call
    axios.post(`${Base_URL}/api/posts/news_letter`, { email })
    .then((response) => {
      // Handle successful response
      console.log('API Response:', response.data);
      setEmail(""); // Clear the email input after successful submission
    })
      .catch((error) => {
        // Handle error
        console.error('API Error:', error);
      });

     await addEmailsToMailingList({email:email})
     toast.success('Subscribed!')
    }catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };


  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }


  return (
    <div className="news_letter_section position-relative">
      <div className="container pb-3">
        <div className="d-md-flex d-none">
          <div className="ms-2">
            <img
              src={news_letter}
              // width={"630px"}
              // height={"269px"}
              alt={`Blog Cover image`}
              className="news_letter_size"
            />
          </div>

          <div className="mt-5 d-none d-md-block">
            <div>
              <h3
                className="sub_title first_to_know_color"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc17) }}
              />
              <p
                className="sub_text first_to_know_color"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par18) }}
              />
            </div>
            <div className="d-flex gap-5 subscribe_block mt-4 subscribe_field_tab">
              <input
                type="email"
                value={email}
                className="form-control outline-none border-0 hover-none rounded-5"
                placeholder="example@xyz.com"
                onChange={handleEmailChange}
              />
              <button type="submit" className="btn btn-primary rounded-5 px-3" onClick={handleSubmit}>
                Subscribe
              </button>
            </div>
            {!isEmailValid && (
                <p className="pt-2" style={{color: "white"}}>{emailError}</p>
              )}
          </div>
        </div>
      </div>
      {/* begin mobile version */}
      <div className="d-block d-md-none">
        <div className="ps-2">
          <img
            src={news_letter_mobile}
            // width={"320px"}
            // height={"200px"}
            alt={`Blog Cover image`}
            className="news_letter_size"
          />
        </div>
        <div className="newsletter_content_mobile">
          <div className="px-3">
            <h3
              className="sub_title first_to_know_color footer_title m-0"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc17) }}
            />
            <p
              className="sub_text first_to_know_color footer_subtext m-0"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par18) }}
            />
          </div>
        </div>
        <div className="d-flex gap-5 subscribe_field_mobile">
          <input
            type="email"
            className="form-control outline-none border-0 hover-none rounded-5"
            placeholder="example@xyz.com"
            name=""
            id=""
          />
          <button type="submit" className="btn btn-primary rounded-5 px-3">
            Subscribe
          </button>
        </div>
      </div>
      {/* end mobile version */}
    </div>
  );
};

export default NewsLetterSection;
