// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-section .logos {
  background: lightblue;
}

/* .logos-heading {
  text-align: center;
} */

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

/* .logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
} */



.logos-slide {
  display: inline-block;
  animation: 60s slide linear infinite;
}


.logos:hover .logos-slide {
  animation-play-state: paused;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

/* @media (max-width: 576px) {
  .logos {
    padding: 30px 0;
  }
  .logos-slide img {
    height: 30px;
    margin: 0 20px;
  }
} */`, "",{"version":3,"sources":["webpack://./src/components/home/partners/partners.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;;GAEG;;AAEH;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;;;;;;;;GAQG;;;;AAIH;EACE,qBAAqB;EACrB,oCAAoC;AACtC;;;AAGA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;;;;;;;;GAQG","sourcesContent":[".partners-section .logos {\n  background: lightblue;\n}\n\n/* .logos-heading {\n  text-align: center;\n} */\n\n.logos {\n  overflow: hidden;\n  padding: 60px 0;\n  background: white;\n  white-space: nowrap;\n  position: relative;\n}\n\n/* .logos:before,\n.logos:after {\n  position: absolute;\n  top: 0;\n  width: 250px;\n  height: 100%;\n  content: \"\";\n  z-index: 2;\n} */\n\n\n\n.logos-slide {\n  display: inline-block;\n  animation: 60s slide linear infinite;\n}\n\n\n.logos:hover .logos-slide {\n  animation-play-state: paused;\n}\n\n@keyframes slide {\n  from {\n    transform: translateX(0);\n  }\n\n  to {\n    transform: translateX(-100%);\n  }\n}\n\n.logos-slide img {\n  height: 50px;\n  margin: 0 40px;\n}\n\n/* @media (max-width: 576px) {\n  .logos {\n    padding: 30px 0;\n  }\n  .logos-slide img {\n    height: 30px;\n    margin: 0 20px;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
