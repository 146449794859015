import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types"; // Import PropTypes
import { Base_URL } from "constant/Constant";


const Reccom = ({ cat }) => {

    const [catPosts, setCatPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${Base_URL}/api/posts/?cat=${cat}`);
                setCatPosts(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [cat]);

    const getTextDesc = (html, maxLength = 150) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        let textContent = doc.body.textContent.trim();
        if (textContent.length > maxLength) {
            textContent = textContent.substring(0, maxLength) + '...';
        }
        return textContent;
    };

    return (
        <div className="blog_section margin_top mb-5">
            <div className="container mb-5">

                <h3
                    className="my-4 sub_title more_article_title"
                >
                    More Articles like this
                </h3>
                <div className="row justify-content-center mb-4 gap-3rem">
                    {catPosts.map((post) => (
                        <div className="col-12 col-md-6 col-lg-4 mb-4" key={post.id}>
                            <div className="card shadow h-100 border cards">
                                <Link Link to={`/blogcontent/${post.id}`} style={{
                                    height: "300px",

                                }}>
                                    <img
                                        src={post.img}
                                        style={{
                                            height: "100%",
                                            objectFit: " cover"
                                        }}
                                        alt={`Blog ${post.title} Cover image`}
                                        className="card-img-top p-2"
                                    />
                                </Link>
                                <div className="card-body d-flex flex-column">
                                    <div className="d-flex justify-content-between gap-2 mb-2">
                                        <div className="d-flex gap-3">
                                            <p className="blog_type_text">Product</p>
                                            <span className="filled_circle"></span>
                                            <p className="blog_type_text">{post.cat}</p>
                                        </div>
                                        <div>
                                            <p className="blog_type_text">{moment(post.date).fromNow()}</p>
                                        </div>
                                    </div>
                                    <Link className="text-decoration-none text-black" to={`/blogcontent/${post.id}`}>
                                        <h5 className="card-title download_app_subtext text-black question_blog fw-semibold">
                                            {post.title}
                                        </h5>
                                    </Link>
                                    <Link className="text-decoration-none text-black mt-auto" to={`/blogcontent/${post.id}`}>
                                        <p className="card-text footer_subtext pb-2">{getTextDesc(post.desc)}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div >


            </div >
        </div >
    );
};

// Add prop types validation
Reccom.propTypes = {
    cat: PropTypes.string.isRequired, // Assuming cat is a string, you can change it to the correct type
};

export default Reccom;
