import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DOMPurify from 'dompurify'
// import { Lottie } from '@crello/react-lottie';
import Clock from '../../assets/images/lottieFiles/clock.json'
import Coin from '../../assets/images/lottieFiles/coin.json'
import Auth from '../../assets/images/lottieFiles/auth.json'
import Lock from '../../assets/images/lottieFiles/lock.json'
import Eye from '../../assets/images/lottieFiles/eye.json'
import { CiClock1 } from 'react-icons/ci'
import { BsCoin } from 'react-icons/bs'
import { FaFingerprint } from 'react-icons/fa'
import { CiLock } from 'react-icons/ci'
import { FaEye } from 'react-icons/fa'
import {
    Box,
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Text,
    Link,
} from '@chakra-ui/react'
import { Base_URL } from 'constant/Constant'

const FasstSection = () => {
    const [homePage, setHomePage] = useState({}) // Use object instead of array

    const manipulaterFun = (obj, field, newValue) => {
        // Manipulate the field data (for demonstration, let's convert the field value to uppercase)
        const manipulatedValue = newValue
        // Replace the field value with the manipulated value
        obj[field] = manipulatedValue
    }

    useEffect(() => {
        const fetchHomePage = async () => {
            try {
                const res = await axios.get(`${Base_URL}/home_page`)
                const data = res.data[0]
                manipulaterFun(
                    data,
                    'title2',
                    "Tago<span class='blue-cash'>Cash</span> is F.A.S.T",
                )
                manipulaterFun(
                    data,
                    'par5',
                    'No hidden fees, What you see, is what you pay',
                )
                manipulaterFun(
                    data,
                    'par2',
                    'Free internal transfer. <br /> 1% to 2% conversion.',
                )
                setHomePage(data)
                // setHomePage(res.data[0]); // Assuming you're expecting one item
                // console.log(res, ">>>>>>>>");
            } catch (err) {
                console.log(err)
            }
        }
        fetchHomePage()
    }, [])

    const clockOptions = {
        loop: true,
        autoplay: true,
        animationData: Clock,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    const coinOptions = {
        loop: true,
        autoplay: true,
        animationData: Coin,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const authOptions = {
        loop: true,
        autoplay: true,
        animationData: Auth,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const eyeOptions = {
        loop: true,
        autoplay: true,
        animationData: Eye,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const lockOptions = {
        loop: true,
        autoplay: true,
        animationData: Lock,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <div className="hero_section">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-4 logo_fast_img"> */}
                    {/* <img
              src={`${Base_URL}/images/${homePage.cover}`}
              width={"500px"}
              height={"416px"}
              alt={`Blog Cover image`}
              className="fasst_mobile"
            /> */}
                    {/* </div> */}
                    <div className="col-lg-12 d-flex align-items-center justify-content-center logo_fast_img">
                        <strong>
                            <h3
                                className="cash_king_title fasst_title"
                                style={{ fontWeight: '700' }}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(homePage.title2),
                                }}
                            />
                        </strong>
                    </div>
                </div>
                <div className="pb-5">
                    {/* <div className="d-md-flex justify-content-center align-items-center">
            <div className="wrap_mobile">
              <h3 className="cash_king_title fasst_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.title2) }} />
            </div>
          </div> */}
                    <Flex flexWrap="wrap" justify="center" gap="30px" mt="2rem">
                        <Box
                            minW="220px"
                            maxW="320px"
                            bg="white"
                            borderRadius="20px"
                            boxShadow="sm"
                            p="40px"
                            textAlign="center"
                            borderBottom="10px solid #afcce4f0"
                            flex={1}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <div style={{ height: '75px' }}>
                                    <div
                                        style={{
                                            background: '#afcce4f0',
                                            padding: '5px',
                                            borderRadius: '7px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CiClock1
                                            style={{
                                                width: '50px',
                                                height: 'auto',
                                                color: '#0066CC',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="text-center pt-2"
                                    style={{ height: '55px' }}
                                >
                                    <h3
                                        className="fasst_subtitle footer_title"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                homePage.desc5,
                                            ),
                                        }}
                                    ></h3>
                                </div>
                                <p
                                    className="fasst_feature_description fasst_fast_text_width pt-1"
                                    style={{ lineHeight: '25px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage.par1,
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            minW="220px"
                            maxW="320px"
                            bg="white"
                            borderRadius="20px"
                            boxShadow="sm"
                            p="40px"
                            textAlign="center"
                            borderBottom="10px solid #afcce4f0"
                            flex={1}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <div style={{ height: '75px' }}>
                                    <div
                                        style={{
                                            background: '#afcce4f0',
                                            padding: '5px',
                                            borderRadius: '7px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BsCoin
                                            style={{
                                                width: '50px',
                                                height: 'auto',
                                                color: '#0066CC',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="text-center pt-2"
                                    style={{ height: '55px' }}
                                >
                                    <h3
                                        className="fasst_subtitle footer_title"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                homePage.desc6,
                                            ),
                                        }}
                                    />
                                </div>
                                <p
                                    className="fasst_feature_description fasst_affordable_text_width pt-1 pixel-styles fasst-text-line m-0"
                                    style={{ lineHeight: '25px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage.par2,
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            minW="220px"
                            maxW="320px"
                            bg="white"
                            borderRadius="20px"
                            boxShadow="sm"
                            p="40px"
                            textAlign="center"
                            borderBottom="10px solid #afcce4f0"
                            flex={1}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <div style={{ height: '75px' }}>
                                    <div
                                        style={{
                                            background: '#afcce4f0',
                                            padding: '5px',
                                            borderRadius: '7px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FaFingerprint
                                            style={{
                                                width: '50px',
                                                height: 'auto',
                                                color: '#0066CC',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="text-center pt-2"
                                    style={{ height: '55px' }}
                                >
                                    <h3
                                        className="fasst_subtitle footer_title"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                homePage.desc7,
                                            ),
                                        }}
                                    />
                                </div>
                                <p
                                    className="fasst_feature_description fasst_safe_text_width pt-2"
                                    style={{ lineHeight: '25px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage.par3,
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            minW="220px"
                            maxW="320px"
                            bg="white"
                            borderRadius="20px"
                            boxShadow="sm"
                            p="40px"
                            textAlign="center"
                            borderBottom="10px solid #afcce4f0"
                            flex={1}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <div style={{ height: '75px' }}>
                                    <div
                                        style={{
                                            background: '#afcce4f0',
                                            padding: '5px',
                                            borderRadius: '7px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FaEye
                                            style={{
                                                width: '50px',
                                                height: 'auto',
                                                color: '#0066CC',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="text-center pt-2"
                                    style={{ height: '55px' }}
                                >
                                    <h3
                                        className="fasst_subtitle footer_title"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                homePage.desc9,
                                            ),
                                        }}
                                    />
                                </div>
                                <p
                                    className="fasst_feature_description fasst_secure_text_width pt-1 wdth-style"
                                    style={{ lineHeight: '25px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage.par5,
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                    </Flex>
                    {/* <div className="d-md-flex fasst_tab mt-5" style={{ lineHeight: "1.7em" }}>
            <div className="d-flex gap-2 mb-xl-0 mb-md-4">
              <div className="ms-2 ms-md-0 me-md-5 fasst_feature fast_feature"
                style={{
                  backgroundColor: "whitesmoke",
                  padding: "0.7em 1.3em",
                  width: "17.5ch",
                  position: "relative",
                  border: "0.9px solid #afcce4f0"
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <CiClock1 style={{ width: "50px", height: "auto", color: "#0066CC" }} />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc5) }}
                  >
                  </h3>
                </div>
                <p
                  className="fasst_feature_description fasst_fast_text_width pt-1"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par1) }}
                />
              </div>


              <div className="ms-md-3 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em",
                width: "17.5ch",
                position: "relative",
                border: "0.9px solid #afcce4f0"

              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <BsCoin style={{ width: "50px", height: "auto", color: "#0066CC" }} />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc6) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_affordable_text_width pt-1 pixel-styles fasst-text-line m-0"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par2) }}
                />
              </div>
            </div>
            <div className="d-flex gap-2 mt-4 mt-md-0 mb-xl-0 mb-md-4">
              <div className="ms-2 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "17.5ch",
                position: "relative",
                border: "0.9px solid #afcce4f0"
              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <FaFingerprint style={{ width: "50px", height: "auto", color: "#0066CC" }} />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc7) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_safe_text_width pt-2"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par3) }}
                />
              </div>
              <div className="ms-md-3 ms-2 me-md-5 fast_feature fast-last-style" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "19ch",
                position: "relative",
                border: "0.9px solid #afcce4f0"
              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <FaEye style={{ width: "50px", height: "auto", color: "#0066CC" }} />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc9) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_secure_text_width pt-1 wdth-style"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par5) }}
                />
              </div>
              
            </div>

          </div> */}
                </div>
            </div>
        </div>
    )
}

export default FasstSection

{
    /* <div className="ms-md-2 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "17.5ch",
                position: 'relative',
                border: "0.9px solid #afcce4f0"
              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Lottie
                      config={{ animationData: Lock, ...lockOptions }}
                      height={50}
                      width={50}
                    />
                    <CiLock style={{ width: "50px", height: "auto", color: "#0066CC" }} />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc8) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_secure_text_width pt-1"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par4) }}
                />
              </div> */
}
