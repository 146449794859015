export enum CountryFinancialOptions {
    BankDeposit = 0,
    BankWithdraw = 1,
    CashDepositWithdraw = 2,
    DebitCreditCardWithdraw = 3,
    DebitCreditCardDeposit = 4,
    MobileMoney = 5,
    TagoPayCard = 6,
    DigitalWallets = 7,
    CryptoDeposit = 8,
    CryptoWithdraw = 9,
    AskUs = 10,
    NotAvailable = 11
}