
import axios from 'axios';
const sendPulseApiBaseUrl = 'https://api.sendpulse.com';
const sendPulseApiUserId = '41b8565d09b300b4bb3db9ac12fe9f2a';
const sendPulseApiSecret = 'b2f33df14ae8ed311dfaa2aeac7f2641';

export const getSendPulseAccessToken = async () => {
  try {
    const data = JSON.stringify({
      grant_type: 'client_credentials',
      client_id: sendPulseApiUserId,
      client_secret: sendPulseApiSecret,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.sendpulse.com/oauth/access_token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const response = await axios.request(config);
    console.log(response,'jjkdjskdjd')
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};








export const addEmailsToMailingList = async (value) => {
    console.log('data',value)
    const mailingListId = 772686
    try {
      const accessToken = await getSendPulseAccessToken();
  
      const data = JSON.stringify({
        emails: [
            {
               "email":value.email||'',
               "variables":{
                  "name":value.firstname||'',
                  "Phone":value.phone||''
               }
            },
            {
               "email":value.email||''       
            }
         ],
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${sendPulseApiBaseUrl}/addressbooks/${mailingListId}/emails`,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
  
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      console.error('Error adding emails to mailing list:', error);
      throw error;
    }
  };











