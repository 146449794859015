import React from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import Flag from "react-world-flags"
interface CountryItemProps {
    code: string;
    countryName:string;
    financialOptions: string[];
}

const CountryItem = (props: CountryItemProps) => {
    const { code, financialOptions,countryName } = props;

    return (
        <div className="countryItemContainer">
            <div className="flagContainer">
                <Flag code={code} size={14} /> 
            </div>
            <span className="countryName"><i>{countryName}</i></span>
            <div className="tooltip">
                {financialOptions.length > 0 ? (
                    financialOptions.map((option, index) => (
                        <div key={index}>
                            {option !== "Not Available" ? (
                                <>
                                    <IoIosCheckmarkCircle style={{ color: 'green',marginRight: '4px' }}/>
                                    { option}
                                </>
                            ) : (
                                <>
                                    <ImCancelCircle style={{ color: 'red',marginRight: '4px' }}/>
                                    { option}
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <div>no info about this country</div>
                )}
            </div>

        </div>
    );
};

export default CountryItem;
