import React, { useState, useEffect } from "react";
import tagocash_logo from 'assets/images/tagocash_logo.png'
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Base_URL } from "constant/Constant";

interface HomePage {
  img: string;
}

const Navbar = () => {

  const [signupActive, setSignupActive] = useState(false);
  const [tagowalletActive, settagowalletActive] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [homePage, setHomePage] = useState<HomePage[]>([]);
  console.log(homePage, "landing page>>>")

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get<HomePage[]>(`${Base_URL}/home_page`);
        setHomePage(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const scrollToTopAndNavigate = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleSignupClick = () => {
    setSignupActive(true);
    scrollToTopAndNavigate(); // Scroll to top on NavLink click
  };

  const handletagowalletClick = () => {
    settagowalletActive(true);
    scrollToTopAndNavigate(); // Scroll to top on NavLink click
  };

  const handleNavLinkClick = () => {
    setSignupActive(false);
    settagowalletActive(false);
  };

  return (
    <nav className="navbar navbar-expand-xl position-sticky top-0 shadow-sm bg-white z-3" style={{ lineHeight: '3rem' }}>
      <div className="container">
        <NavLink className="navbar-brand" to="/" onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); toggleCollapse(); }}>
          {homePage.map((page, index) => (
            <div key={index}>
              <img
                src={`${Base_URL}/images/${page.img}`}
                alt="PhoneImg"
                width={"218px"}
                height={"43px"}
              />
            </div>
          ))}
          {/* <img src={tagocash_logo} width={"218px"} height={"43px"} alt="" /> */}
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          style={{ textAlign: "center" }}
          className={`collapse navbar-collapse ${isCollapsed ? 'show' : ''}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav m-auto">
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/contact" onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); toggleCollapse(); }}>
                Help & Support
              </NavLink>
            </li>
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/faq" onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); toggleCollapse(); }}>
                FAQ&apos;s
              </NavLink>
            </li>
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/blog" onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); toggleCollapse(); }}>
                Blogs
              </NavLink>
            </li>
          </ul>

          <NavLink
            to="/signup"
            onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); handleSignupClick(); toggleCollapse(); }}
            className={`my-2 me-3 btn ${signupActive ? 'button_color' : 'border-primary'} ms-md-0 ms-3 rounded-5 px-xl-5 py-xl-2 px-lg-3 py-lg-2 px-5 py-2`}
          >
            <div className={`fs-6 text-decoration-none ${signupActive ? 'text-white' : 'text-primary'} fw-normal`}>
              Sign Up
            </div>
          </NavLink>
          <br />
          <NavLink
            to="/wallet"
            onClick={() => { handleNavLinkClick(); scrollToTopAndNavigate(); handletagowalletClick(); toggleCollapse(); }}
            className={`border btn ${tagowalletActive ? 'button_color' : 'border-primary'} rounded-5 px-xl-4 py-xl-2 px-lg-3 py-lg-2 px-4 py-2`}
          >
            <div className={`fs-6 text-decoration-none ${tagowalletActive ? 'text-white' : 'text-primary'} fw-normal`}>
              Open TagoWallet
            </div>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
