import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DOMPurify from "dompurify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blog_content_right from "../../../assets/images/blog_content_right.png";
import google_play_cta from "../../../assets/images/google_play_cta.svg";
import app_store_cta from "../../../assets/images/app_store_cta.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import twitter from "../../../assets/images/twitter.svg";
import instagram from "../../../assets/images/instagram.svg";
import facebook from "../../../assets/images/facebook.svg";
import formkit_link from "../../../assets/images/formkit_link.svg";
import NewsLetterSection from "../../../components/NewsLetterSection";
import Reccom from "./reccom";
import { Base_URL } from "constant/Constant";

const BlogContent = () => {
  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts or the URL changes
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts/${postId}`);
        console.log('Fetched data from backend:', res.data); // Add this console.log statement to see all data fetched from the backend
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);


  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  const sanitizedDesc = DOMPurify.sanitize(post.desc, {
    ALLOWED_TAGS: ['a', 'b', 'br', 'blockquote', 'code', 'caption', 'div', 'em', 'i', 'img', 'li', 'ol', 'p', 'pre', 's', 'strike', 'strong', 'sub', 'sup', 'u', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'],
    ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'class', 'width', 'height', 'style', 'align', 'color', 'background', 'font', 'size', 'direction', 'value', 'list', 'script', 'indent'],
  });


  const sanitizedDescWithClass = sanitizedDesc.replace(/<img/g, '<img class="blog_img_size" width="800px" height="480px" alt="blog_img_size'); // Add width and height directly


  const estimateReadingTime = (text) => {
    // Assuming average reading speed of 200 words per minute
    const wordsPerMinute = 200;
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  };


  // BREADCRUMP SEC
  const breadcrumbStyle = {
    "--bs-breadcrumb-divider": "'>",
  };
  return (
    <div className="blog_section margin_top mb-5">
      <div className="container mb-5">
        <NavLink to="/blog" className="text-decoration-none text-black">
          <p className="my-4 d-block d-md-none">&lt; Back</p>
        </NavLink>
        <nav
          className="d-md-block d-none"
          style={breadcrumbStyle}
          aria-label="breadcrumb"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink className="text-decoration-none text-black" to="/">
                Home
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink className="text-decoration-none text-black" to="/blog">
                Blogs
              </NavLink>

            </li>
            <li className="breadcrumb-item text-black active" aria-current="page">
              {post.cat}
            </li>
          </ol>
        </nav>
        <div className="blog_content_block gap-5 d-flex">
          <div className="left_blog_content">
            <div>
              <h3 className="sub_title blog_content_title footer_title">
                {post.title}
              </h3>
              <div className="d-flex gap-3 mt-3">
                <p className="blog_type_text blogs_page_subtext text-black d-block d-md-none">
                  {moment(post.date).fromNow()}
                </p>
                <span className="filled_circle_blog d-block d-md-none"></span>

                <p className="blog_type_text blogs_page_subtext text-black d-block d-md-none">
                  {estimateReadingTime(getText(post.desc))} Min Read
                </p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <div className="d-flex gap-3">
                  <p className="blog_type_text blogs_page_subtext">Product</p>
                  <span className="filled_circle_blog bg-primary"></span>
                  <p className="blog_type_text blogs_page_subtext">
                    {post.cat}
                  </p>
                </div>

                <div className="d-flex gap-3">
                  <p className="blog_type_text blogs_page_subtext text-black d-none d-md-block">
                    {moment(post.date).fromNow()}
                  </p>
                  <span className="filled_circle_blog d-none d-md-block"></span>

                  <p className="blog_type_text blogs_page_subtext text-black d-none d-md-block">
                    {estimateReadingTime(getText(post.desc))} Min Read
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div>
                <p
                  className="download_app_subtext text-black blogs_subtext"
                  dangerouslySetInnerHTML={{ __html: sanitizedDescWithClass }}
                ></p>
              </div>
            </div>
          </div>

          <div className="right_side_block d-lg-block d-none">
            <div className="right_side_content">
              <div className="download_block text-center">
                <h3 className="sub_title fast_secure_title pt-4 pb-2">
                  Fast. Free. <br />
                  Secure.
                </h3>
                <p className="download_app_subtext text-black">
                  Download the Tagocash App Now!
                </p>
                <div className="img_app_block">
                  <div>
                    <img src={blog_content_right} width={"360px"} alt="blog_content_right" />
                  </div>

                  <div className="d-flex flex-column gap-3 app_link_block">
                    <a className="text-decoration-none" href="">
                      <img
                        src={google_play_cta}
                        width={"166px"}
                        height={"47px"}
                        alt="google play"
                      />
                    </a>

                    <a className="text-decoration-none" href="">
                      <img
                        src={app_store_cta}
                        width={"166px"}
                        height={"47px"}
                        alt="app store"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="link_block d-flex border rounded-3 mt-5">
                <div className="p-4 border border-start-0 border-top-0 border-bottom-0">
                  <a href="">
                    <img src={linkedin} width={"24px"} height={"24px"} alt="linkedin" />
                  </a>
                </div>

                <div className="p-4 border border-start-0 border-top-0 border-bottom-0">
                  <a href="">
                    <img
                      src={instagram}
                      width={"24px"}
                      height={"24px"}
                      alt="instagram"
                    />
                  </a>
                </div>

                <div className="p-4 border border-start-0 border-top-0 border-bottom-0">
                  <a href="">
                    <img src={twitter} width={"24px"} height={"24px"} alt="twitter" />
                  </a>
                </div>

                <div className="p-4 border border-start-0 border-top-0 border-bottom-0">
                  <a href="">
                    <img src={facebook} width={"24px"} height={"24px"} alt="formkit_link" />
                  </a>
                </div>

                <div className="p-4">
                  <a href="">
                    <img
                      src={formkit_link}
                      width={"24px"}
                      height={"24px"}
                      alt="formkit_link"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Reccom cat={post.cat} />
      </div>
      <NewsLetterSection />
    </div>
  );
};

export default BlogContent;
