import React, { useEffect, useState } from "react";
import blue_background from "../../../assets/images/blue_background.png";
import Lottie from "react-lottie";
import axios from "axios"
import contactUsLottie from "../../../assets/images/lottieFiles/contactUs.json";
import TagoLottie from "../../../assets/images/lottieFiles/tagolotie.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userCountry, setUserCountry] = useState('');
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [isNameValidone, setIsNameValidone] = useState(true);
  const [isNameValidtwo, setIsNameValidtwo] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isCheckedValid, setIsCheckedValid] = useState(true);

  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isCheckedError, setIsCheckedError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isRecaptchaCompleted, setIsRecaptchaCompleted] = useState(false);

  useEffect(() => {
    // Function to fetch user's country based on IP address
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://api.ipbase.com/v1/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setUserCountry(data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchUserCountry();
  }, []);

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setIsNameValidone(newFirstName.trim() !== "");
    setFirstNameError(newFirstName.trim() !== "" ? "" : "First Name cannot be empty");
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setIsNameValidtwo(newLastName.trim() !== "");
    setLastNameError(newLastName.trim() !== "" ? "" : "Last Name cannot be empty");
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handlePhoneChange = (value, country) => {
    const newPhoneNumber = value;
    setPhoneNumber(newPhoneNumber);
    setIsPhoneNumberValid(newPhoneNumber.trim() !== '');
    setPhoneNumberError(newPhoneNumber.trim() !== '' ? '' : 'Phone cannot be empty');
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    setIsMessageValid(newMessage.trim() !== "");
    setMessageError(newMessage.trim() !== "" ? "" : "Message cannot be empty");
  };

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    setIsCheckedValid(newIsChecked);
    setIsCheckedError(newIsChecked ? "" : "You must agree to the terms");
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setIsRecaptchaCompleted(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Check if all fields are filled and terms are agreed upon
      const isFormValid =
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        email.trim() !== "" &&
        phoneNumber.trim() !== "" &&
        message.trim() !== "" &&
        isChecked &&
        recaptchaValue !== null; // Include the check for reCAPTCHA value

      if (!isFormValid) {
        // If form is not valid, set error states and return
        setIsNameValidone(firstName.trim() !== "");
        setIsNameValidtwo(lastName.trim() !== "");
        setIsEmailValid(email.trim() !== "");
        setIsPhoneNumberValid(phoneNumber.trim() !== "");
        setIsMessageValid(message.trim() !== "");
        setIsCheckedValid(isChecked);

        setFirstNameError(firstName.trim() !== "" ? "" : "First Name cannot be empty");
        setLastNameError(lastName.trim() !== "" ? "" : "Last Name cannot be empty");
        setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
        setPhoneNumberError(
          phoneNumber.trim() !== "" ? "" : "Phone cannot be empty"
        );
        setMessageError(message.trim() !== "" ? "" : "Message cannot be empty");
        setIsCheckedError(isChecked ? "" : "You must agree to the terms");

        // Check if reCAPTCHA value is null and set error state if needed
        if (recaptchaValue === null) {
          setRecaptchaError("reCAPTCHA validation failed");
        } else {
          setRecaptchaError("");
        }

        return; // Exit function if form is not valid
      }

      // If form is valid, make the API call
      const response = await axios.post(
        `${Base_URL}/api/posts/send_email`,
        {
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone: phoneNumber,
          message: message,
        }
      );

      // console.log("Form submitted:", {
      //   firstName,
      //   lastName,
      //   email,
      //   phoneNumber,
      //   message,
      //   isChecked,
      // });

      // Clear form fields after successful submission
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      setIsChecked(false);
      setIsRecaptchaCompleted(false);

    } catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };


  const contactOptions = {
    loop: true,
    autoplay: true,
    animationData: contactUsLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="contact_section position-relative">
      <div className="d-block d-md-none blue_background">
        <img
          className="position-absolute top-0 left-0"
          src={blue_background}
          width={"90%"}
          height={"300px"}
          alt=""
        />
      </div>
      <div className="container position-relative pb-5">
        <div className="d-lg-flex align-items-center py-md-5 pb-3">
          <a href="/" className="text-decoration-none text-black">
            <p className="d-block d-md-none pt-md-4 pt-3">&lt; Back</p>
          </a>
          <div className="left_sided_contact margin_top pb-md-5 contact_block">
            <div id="contact_lottie" className="d-none d-lg-block">
              <Lottie options={contactOptions} height={339} width={373} />
            </div>
          </div>
          <div className="form_section">
            <div>
              <h3 className="hero_title contact_title mb-md-1">Contact us</h3>
              <p className="sub_text contact_subtext mb-4">
                We welcome your questions or comments
              </p>
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={firstName}
                onChange={handleFirstNameChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput1"
                placeholder="First Name*"
              />
              {!isNameValidone && <p className="text-danger pt-2">{firstnameError}</p>}
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={lastName}
                onChange={handleLastNameChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput1"
                placeholder="Last Name*"
              />
              {!isNameValidtwo && <p className="text-danger pt-2">{lastnameError}</p>}
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput2"
                placeholder="Email*"
              />
              {!isEmailValid && (
                <p className="text-danger pt-2">{emailError}</p>
              )}
            </div>

            <div>
              <PhoneInput
                country={userCountry}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="phone number"
              />
              {!isPhoneNumberValid && (
                <p className="text-danger pt-2">{phoneNumberError}</p>
              )}
            </div>
            <div className="my-4">
              <textarea
                name="textArea"
                value={message}
                onChange={handleMessageChange}
                className="form-control rounded-5 px-3 py-3 border-0"
                id="exampleFormControlTextarea4"
                rows="3"
                placeholder="Message*"
              ></textarea>
              {!isMessageValid && (
                <p className="text-danger pt-2">{messageError}</p>
              )}
            </div>
            <div>
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input rounded-0 p-2 me-3 pointer"
                  type="checkbox"
                  name="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"

                />
                <label
                  className="form-check-label contact_subtext fs-6"
                  htmlFor="flexCheckDefault"
                >
                  Please Send me <strong>Updates</strong> on Tagocash.
                </label>
              </div>
              {!isCheckedValid && (
                <p className="text-danger pt-2">{isCheckedError}</p>
              )}
            </div>
            <div className="mt-4">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={handleRecaptchaChange}
              />
            </div>
            <div className="mb-5 mt-4">
              <button
                onClick={handleSubmit}
                disabled={
                  !isNameValidone ||
                  !isNameValidtwo ||
                  !isEmailValid ||
                  !isPhoneNumberValid ||
                  !isMessageValid ||
                  !isCheckedValid ||
                  !isRecaptchaCompleted
                }
                type="button"
                className="btn button_color text-white rounded-5 px-5 py-2"
                aria-label="accordion-button collapsed faq_bg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;