import React, { useEffect, useState } from "react";
import axios from "axios"
import DOMPurify from 'dompurify'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";


function FaqPage() {
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  const [faqPage, setFaqPage] = useState({}); // Use object instead of array

  useEffect(() => {
    const fetchFaqPage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/faq_page`);
        setFaqPage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchFaqPage();
  }, []);
  return (
    <div className="container">
      <h3 className="sub_title my-5 text-center get_more_title">
        Frequently Asked Questions
      </h3>

      <div className="accordion pb-3" id="accordionExample">
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc1) }}
            />

          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par1) }} />

          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm rounded-3 mt-4">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc2) }}
            />

          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par2) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm rounded-3 mt-4">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc3) }}
            />
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par3) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm rounded-3 mt-4">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc4) }}
            />
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par4) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm rounded-3 mt-4">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc5) }}
            />
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par5) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc6) }}
            />
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par6) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc7) }}
            />
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par7) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc8) }}
            />
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par8) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc9) }}
            />
          </h2>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par9) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc10) }}
            />
          </h2>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par10) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc11) }}
            />
          </h2>
          <div
            id="collapseEleven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par11) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc12) }}
            />
          </h2>
          <div
            id="collapseTwelve"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par12) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirteen"
              aria-expanded="false"
              aria-controls="collapseThirteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc13) }}
            />
          </h2>
          <div
            id="collapseThirteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par13) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFourteen"
              aria-expanded="false"
              aria-controls="collapseFourteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc14) }}
            />
          </h2>
          <div
            id="collapseFourteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par14) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFifteen"
              aria-expanded="false"
              aria-controls="collapseFifteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc15) }}
            />
          </h2>
          <div
            id="collapseFifteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par15) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSixteen"
              aria-expanded="false"
              aria-controls="collapseSixteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc16) }}
            />
          </h2>
          <div
            id="collapseSixteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par16) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeventeen"
              aria-expanded="false"
              aria-controls="collapseSeventeen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc17) }}
            />
          </h2>
          <div
            id="collapseSeventeen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par17) }} />
          </div>
        </div>

        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEighteen"
              aria-expanded="false"
              aria-controls="collapseEighteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc18) }}
            />
          </h2>
          <div
            id="collapseEighteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par18) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNineteen"
              aria-expanded="false"
              aria-controls="collapseNineteen"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc19) }}
            />
          </h2>
          <div
            id="collapseNineteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par19) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwenty"
              aria-expanded="false"
              aria-controls="collapseTwenty"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc20) }}
            />
          </h2>
          <div
            id="collapseTwenty"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par20) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyOne"
              aria-expanded="false"
              aria-controls="collapseTwentyOne"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc21) }}
            />
          </h2>
          <div
            id="collapseTwentyOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par21) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyTwo"
              aria-expanded="false"
              aria-controls="collapseTwentyTwo"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc22) }}
            />
          </h2>
          <div
            id="collapseTwentyTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par22) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyThree"
              aria-expanded="false"
              aria-controls="collapseTwentyThree"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc23) }}
            />
          </h2>
          <div
            id="collapseTwentyThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par23) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyFour"
              aria-expanded="false"
              aria-controls="collapseTwentyFour"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc24) }}
            />
          </h2>
          <div
            id="collapseTwentyFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par24) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyFive"
              aria-expanded="false"
              aria-controls="collapseTwentyFive"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc25) }}
            />
          </h2>
          <div
            id="collapseTwentyFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par25) }} />
          </div>
        </div>
        <div className="accordion-item border-0 shadow-sm mt-4 rounded-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed faq_bg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentySix"
              aria-expanded="false"
              aria-controls="collapseTwentySix"
              aria-label="accordion-button collapsed faq_bg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.desc26) }}
            />
          </h2>
          <div
            id="collapseTwentySix"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body faq_bg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqPage.par26) }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
