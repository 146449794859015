import { Button, useDisclosure } from '@chakra-ui/react'
import { Safe } from '../../assets/icons'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiShield } from 'react-icons/fi'; // Import the security icon from Chakra UI
import PrivcyModal from '../modal'
import axios from 'axios';
import ipinfo from 'ipinfo';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { Base_URL } from 'constant/Constant';

export default function CookiesModal() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Check if the acceptedCookie exists in the cookies
        const acceptedCookie = document.cookie.includes('acceptedCookie=true');
        if (!acceptedCookie) {
            // If the cookie does not exist, set the modal to be open
            setIsModalOpen(true);
        }
    }, []);

    const closeModal = async () => {

        setIsModalOpen(false);
        try {
            // Send a POST request to the backend to increment the cookie count and set the acceptedCookie in the cookies
            const response = await axios.post(`${Base_URL}/increment-cookie-count`);
            const cookieCountResponse = await axios.post(`${Base_URL}/increment-visitor`);

            document.cookie = 'acceptedCookie=true; max-age=31536000; path=/';

            const ipResponse = await axios.get('https://ipinfo.io?token=977e06d01e2778');
            const ipDetails = ipResponse.data;
            const timestamp = new Date();

            const details = {
                ip: ipDetails.ip,
                country: ipDetails.country,
                city: ipDetails.city,
                latitude: ipDetails.loc.split(',')[0],
                longitude: ipDetails.loc.split(',')[1],
                browser: navigator.userAgent,
                device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : isBrowser ? 'Browser' : 'Other',
                timestamp: timestamp.toISOString(),
            };

            const postResponse = await axios.post(`${Base_URL}/collectVisitorDetails`, details);
            console.log('User details collected and sent to the backend:', postResponse.data);
        } catch (error) {
            console.error('Error collecting and sending user details:', error);
        }
    };

    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const settings = {
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        afterChange: (index: number) => {
            setActiveSlideIndex(index)
        },
    }

    return (
        <>
            {isModalOpen && (
                <div className="cookies-content" style={{zIndex: "2"}}>
                    {/* DESK TOP */}
                    <div className="desktop">
                        <p className="desktopMode">
                            <span>
                                <h1 style={{ color: '#0066CC' }}> Want to help us improve your experience, Accept Cookies.</h1>
                            </span>
                            {/*<br />*/}
                            Enabling these optional cookies helps us provide you with a better, more personalized experience, like your
                            preferred language. To learn more, see our <Link to="/privacy"><span style={{ color: "#0066CC" }}>Privacy Policy</span></Link>
                            . By using the service, you agree to the <Link to="/terms"><span style={{ color: "#0066CC" }}>Terms of Service</span></Link>.
                        </p>
                        <Button colorScheme="teal" className="btn-accept" variant="outline" onClick={closeModal}>
                            Accept
                        </Button>
                    </div>

                    {/* MOBILE */}
                    <div className="mobile">
                        <div className='safe'>
                            <Safe />
                        </div>
                        <p className="mobileMode" style={{ lineHeight: '2.3' }}>
                            <span>
                                <h1 style={{ color: '#0066CC' }}> Want to help us improve your experience, Accept Cookies.</h1>

                            </span>
                            Enabling these optional cookies helps us provide you with a better, more personalized experience, like your
                            preferred language. To learn more, see our
                            <Link color="teal" to="/privacy">
                                Privacy Policy
                            </Link>
                            . By using the service, you agree to the
                            <Link color="teal" to="/terms">
                                Terms of Service
                            </Link>
                            .
                        </p>
                        <br />
                        <Button className="btn-accept-mobile" onClick={closeModal}>
                            Accept Cookies
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

