import React, { useEffect, useState } from "react";
import axios from "axios"
import DOMPurify from 'dompurify'
import hero_tick from "../../../assets/images/hero_tick.svg";
import tick_mark from "../../../assets/images/tick_mark.svg";
import Slider from "react-slick";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Base_URL } from "constant/Constant";
import countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import en from 'i18n-iso-countries/langs/en.json';
import TagoLottie from "../../../assets/images/lottieFiles/tagolotie.json";
import Lottie from "react-lottie";
import { addEmailsToMailingList, getSendPulseAccessToken } from "utils/sendPulse.utils";
import { toast } from "react-toastify";
countries.registerLocale(en);
const countryList = Object.entries(countries.getNames('en')).map(([code, name]) => ({ code, name }));

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userCountry, setUserCountry] = useState('');

  const [isNameValidone, setIsNameValidone] = useState(true);
  const [isNameValidtwo, setIsNameValidtwo] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCountryNameValid, setIsCountryNameValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [CountryNameError, setCountryNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(false);

  const handleClick1 = () => {
    setIsClicked1(true);
    setIsClicked2(false);

  };

  const handleClick2 = () => {
    setIsClicked1(false);
    setIsClicked2(true);
  };

  const handleCloseModal = () => {
    setIsClicked1(false);
    setIsClicked2(false);
  };

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setIsNameValidone(newFirstName.trim() !== "");
    setFirstNameError(newFirstName.trim() !== "" ? "" : "First Name cannot be empty");
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setIsNameValidtwo(newLastName.trim() !== "");
    setLastNameError(newLastName.trim() !== "" ? "" : "Last Name cannot be empty");
  };


  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setIsCountryNameValid(selectedCountry !== "");
    setCountryNameError(selectedCountry !== "" ? "" : "Please select a country");
  };

  const handlePhoneChange = (value, country) => {
    const newPhoneNumber = value;
    setPhoneNumber(newPhoneNumber);
    setIsPhoneNumberValid(newPhoneNumber.trim() !== '');
    setPhoneNumberError(newPhoneNumber.trim() !== '' ? '' : 'Phone cannot be empty');
  };

  const handleSubmit = async (event) => { // Add async keyword here
    event.preventDefault();

    try {
      // Check if all fields are filled and terms are agreed upon
      const isFormValid =
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        email.trim() !== "" &&
        country.trim() !== "" &&
        phoneNumber.trim() !== "";

      if (!isFormValid) {
        // If form is not valid, set error states and return
        setIsNameValidone(firstName.trim() !== "");
        setIsNameValidtwo(lastName.trim() !== "");
        setIsEmailValid(email.trim() !== "");
        setIsCountryNameValid(country.trim() !== "");
        setIsPhoneNumberValid(phoneNumber.trim() !== "");

        setFirstNameError(firstName.trim() !== "" ? "" : "First Name cannot be empty");
        setLastNameError(lastName.trim() !== "" ? "" : "Last Name cannot be empty");
        setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
        setCountryNameError(country !== "" ? "" : "Please select a country");
        setPhoneNumberError(
          phoneNumber.trim() !== "" ? "" : "Phone cannot be empty"
        );

        return; // Exit function if form is not valid
      }

      // If form is valid, make the API call
      const response = await axios.post(
        `${Base_URL}/api/posts/customer`,
        {
          firstname: firstName,
          lastname: lastName,
          email: email,
          country: country,
          phone: phoneNumber,
        }
      );


      await addEmailsToMailingList({
        firstname: firstName,
        lastname: lastName,
        email: email,
        country: country,
        phone: phoneNumber,
      })
      // console.log("Form submitted:", {
      //   firstName,
      //   lastName,
      //   email,
      //   country,
      //   phoneNumber,
      //   isChecked,
      // });

      // Clear form fields after successful submission
      setFirstName("");
      setLastName("");
      setEmail("");
      setCountry("");
      setPhoneNumber("");

      toast.success('Success!')
    } catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };

  const manipulaterFun = (obj, field, newValue) => {

    // Manipulate the field data (for demonstration, let's convert the field value to uppercase)
    const manipulatedValue = newValue
    // Replace the field value with the manipulated value
    obj[field] = manipulatedValue;
  }

  var plansSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [signUpPage, setSignUpPage] = useState({});
  // console.log(signUpPage, "Registration starts June 15th, 2024")
  const [loading, setLoading] = useState(true);
  // console.log(signUpPage, ":::::");

  useEffect(() => {
    const fetchSignUpPage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/signup_page`);
        const data = res.data[0]
        // manipulaterFun(data, "desc", "Join the Tago Community, Now!")
        setSignUpPage(data);
        setLoading(false); // Set loading to false after data is fetched 
      } catch (err) {
        console.log(err);
        setLoading(false); // Also set loading to false in case of an error
      }
    };
    fetchSignUpPage();
  }, []);

  useEffect(() => {
    // Function to fetch user's country based on IP address
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://api.ipbase.com/v1/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setUserCountry(data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching user country:', error);
        // You can add additional error handling logic here, such as informing the user or retrying the request
      }
    };

    fetchUserCountry(); // Fetch user's country when component mounts
  }, []);

  const contactOptions = {
    loop: true,
    autoplay: true,
    animationData: TagoLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {
        loading ? (
          <div>
            <Lottie
              options={contactOptions}
              height={250}
              width={250}
            />
          </div>
        ) : (
          <div className="container pb-5">
            <div className="d-xl-flex align-items-center gap">
              <div className="mt-5 text-center text-xl-start">
                <p className="fasst_feature_description lh-base" style={{ color: "red" }}>
                  Registration starts September 15th, 2024
                </p>
                <button
                  type="button"
                  className={`btn border-primary rounded-5 px-4 ${isClicked1 ? 'button_color' : 'butn_color'}`}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1" onClick={handleClick1}
                  aria-label="Register to be notified"
                >
                  Register to be notified
                </button>
                <h3 className="membership_title mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title) }} />
                <p className="fasst_feature_description lh-base" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc) }} />
              </div>
              <div className="d-none d-md-flex gap-5 justify-content-center mt-5">
                <div className="card shadow border-0 cards">
                  <div className="free_plan text-center p-3">
                    <h3 className="plan_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title2) }} />
                    <div className="plan_duration">
                      <p className="download_app_subtext fs-6 py-1 p-0 m-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc1) }} />
                    </div>
                    <div className="text-white">
                      <span className="fasst_title" style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title3) }} />
                      <span className="fs-6">/ Annually</span>
                    </div>
                    <p className="download_app_subtext mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc2) }} />
                  </div>
                  <div className="card-body">
                    <div className="d-flex gap-3 align-items-start">
                      <img src={tick_mark} width={"23px"} height={"23px"} alt="tick_mark" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={tick_mark} width={"23px"} height={"23px"} alt="tick_mark" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par1) }} />

                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={tick_mark} width={"23px"} height={"23px"} alt="tick_mark" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par2) }} />

                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={tick_mark} width={"23px"} height={"23px"} alt="tick_mark" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par3) }} />

                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={tick_mark} width={"23px"} height={"23px"} alt="tick_mark" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par4) }} />

                    </div>
                    <div>
                      <p className="fst-italic blog_type_text text-dark" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc3) }} />
                    </div>
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className={`btn border-primary rounded-5 px-4 ${isClicked1 ? 'button_color' : 'butn_color'}`}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop1" onClick={handleClick1}
                        aria-label="Register to be notified about updates"
                      >
                        Register to be notified
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="card shadow border-0 cards">
                  <div className="annual_plan text-center p-3">
                    <h3 className="plan_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title4) }} />
                    <div className="plan_duration my-4">
                      <p className="download_app_subtext fs-6 py-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc4) }} />
                    </div>
                    <div className="pb-5 pt-3">
                      <h4 className="text-white cookie_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title5) }} />
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="footer_subtext pt-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc5) }} />
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par5) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par6) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par7) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par8) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par9) }} />
                    </div>
                    <div className="d-flex gap-3 align-items-start">
                      <img src={hero_tick} width={"23px"} height={"23px"} alt="" />
                      <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par10) }} />
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className={`btn border-primary rounded-5 px-4 ${isClicked2 ? 'button_color' : 'butn_color'}`}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop2"
                        onClick={handleClick2}
                      >
                        Register to be Notified
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>


              {/* Modal Tago Fan */}
              <div
                className="modal fade"
                id="staticBackdrop1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel1"
                aria-hidden="true"
                onClick={handleCloseModal}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="py-md-4 px-5">
                      <h1
                        className="modal-title modal_title text-center text-md-start"
                        id="staticBackdropLabel"
                      >
                        Tago Fan{" "}
                      </h1>
                      <h4 className="modal_subtext py-1 text-center text-md-start">
                        Tago Hero Coming in July 2024
                      </h4>
                    </div>
                    <div className="modal-body px-5">
                      <div className="mb-4">
                        <input
                          type="text"
                          value={firstName}
                          onChange={handleFirstNameChange}
                          className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                          placeholder="First Name"
                          id="firstNameInput"
                        />
                        {!isNameValidone && (
                          <p className="text-danger pt-2">{firstnameError}</p>
                        )}
                      </div>
                      <div className="mb-4">
                        <input
                          type="text"
                          value={lastName}
                          onChange={handleLastNameChange}
                          className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                          placeholder="Last Name"
                          id="LastNameInput"
                        />
                        {!isNameValidtwo && (
                          <p className="text-danger pt-2">{lastnameError}</p>
                        )}
                      </div>
                      <div className="mb-4">
                        <input
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                          className="form-control rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                          id="emailFormControlInput"
                          placeholder="Enter Email"

                        />
                        {!isEmailValid && (
                          <p className="text-danger pt-2">{emailError}</p>
                        )}
                      </div>
                      <div className="mb-4">
                        <select
                          className="form-select rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                          value={country}
                          onChange={handleCountryChange}
                          aria-required="true"
                        >
                          <option value="">Select Country</option>
                          {countryList.map((country) => (
                            <option key={country.code} value={country.code}>
                              <ReactCountryFlag countryCode={country.code} svg /> {country.name}
                            </option>
                          ))}
                        </select>
                        {!isCountryNameValid && (
                          <p className="text-danger pt-2">{CountryNameError}</p>
                        )}
                      </div>
                      <div>
                        <PhoneInput
                          className="wallet_input_bg"
                          country={userCountry}
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          // enableSearch={true}
                          // countryCodeEditable={false}
                          placeholder={"Enter Phone Number"}
                        // inputProps={{
                        //   id: "phone_number",
                        //   autoFocus: true,
                        // }}
                        />
                        {!isPhoneNumberValid && (
                          <p className="text-danger pt-2">{phoneNumberError}</p>
                        )}
                      </div>
                      <div className="mb-5 mt-4">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          disabled={
                            !isNameValidone ||
                            !isNameValidtwo ||
                            !isEmailValid ||
                            !isCountryNameValid ||
                            !isPhoneNumberValid
                          }
                          className="btn button_color text-white rounded-5 px-4 py-2 mb-md-5"
                          aria-label=" Pre-Register"
                        >
                          Pre-Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Modal Tago Hero */}
              {/* <div
                className="modal fade"
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel2"
                aria-hidden="true"
                onClick={handleCloseModal}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="py-md-4 px-5">
                      <h1
                        className="modal-title modal_title text-center text-md-start"
                        id="staticBackdropLabel"
                      >
                        Pre-Register to be Notified{" "}
                      </h1>
                      <h4 className="modal_subtext py-1 text-center text-md-start">
                        Tago Hero Coming in July 2024
                      </h4>
                    </div>
                    <div className="modal-body px-5">
                      <div className="mb-4">
                        <input
                          type="text"
                          value={name}
                          onChange={handleNameChange}
                          className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                          id="nameFormControlInput"
                          placeholder="Enter Name"
                        />
                        {!isNameValid && (
                          <p className="text-danger pt-2">{nameError}</p>
                        )}
                      </div>
                      <div className="mb-4">
                        <input
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                          className="form-control rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                          id="emailFormControlInput"
                          placeholder="Enter Email"
                        />
                        {!isEmailValid && (
                          <p className="text-danger pt-2">{emailError}</p>
                        )}
                      </div>
                      <div>
                        <PhoneInput
                          className="wallet_input_bg"
                          key={formKey}
                          country={userCountry}
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          enableSearch={true}
                          countryCodeEditable={false}
                          placeholder={"Enter Phone Number"}
                          inputProps={{
                            id: "phone_number",
                            autoFocus: true,
                          }}
                        />
                        {!isPhoneNumberValid && (
                          <p className="text-danger pt-2">{phoneNumberError}</p>
                        )}
                      </div>
                      <div className="mb-5 mt-4">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          disabled={
                            !isNameValid || !isEmailValid || !isPhoneNumberValid
                          }
                          className="btn button_color text-white rounded-5 px-4 py-2 mb-md-5"
                        >
                          Pre-Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            {/* begin mobile */}
            <div className="d-md-none d-block mt-4">
              {/* <Slider {...plansSettings}> */}
              <div>
                <div className="me-2">
                  <div className="card border cards mb-5">
                    <div className="free_plan text-center p-3">
                      <h3 className="plan_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title2) }} />
                      <div className="plan_duration">
                        <p className="download_app_subtext fs-6 py-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc1) }} />
                      </div>
                      <div className="text-white">
                        <span className="fasst_title" style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title3) }} />
                        <span className="fs-6">/ Annually</span>
                      </div>
                      <p className="download_app_subtext mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc2) }} />
                    </div>
                    <div className="card-body">
                      <div className="d-flex gap-3 align-items-start">
                        <img
                          src={tick_mark}
                          width={"23px"}
                          height={"23px"}
                          alt="tick_mark"
                        />
                        <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par) }} />

                      </div>
                      <div className="d-flex gap-3 align-items-start">
                        <img
                          src={tick_mark}
                          width={"23px"}
                          height={"23px"}
                          alt="tick_mark"
                        />
                        <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par1) }} />

                      </div>
                      <div className="d-flex gap-3 align-items-start">
                        <img
                          src={tick_mark}
                          width={"23px"}
                          height={"23px"}
                          alt="tick_mark"
                        />
                        <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par2) }} />

                      </div>
                      <div className="d-flex gap-3 align-items-start">
                        <img
                          src={tick_mark}
                          width={"23px"}
                          height={"23px"}
                          alt="tick_mark"
                        />
                        <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par3) }} />

                      </div>
                      <div className="d-flex gap-3 align-items-start">
                        <img
                          src={tick_mark}
                          width={"23px"}
                          height={"23px"}
                          alt="tick_mark"
                        />
                        <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par4) }} />

                      </div>
                      <div>
                        <p className="fst-italic blog_type_text text-dark" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc3) }} />
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="button"
                          className={`btn border-primary rounded-5 px-4 ${isClicked1 ? 'button_color' : 'butn_color'}`}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop1"
                          onClick={handleClick1}
                          aria-label="Register to be notified"

                        >
                          Register to be notified
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* <div className="me-2">
                    <div className="card border cards mb-5">
                      <div className="annual_plan text-center p-3">
                        <h3 className="plan_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title4) }} />
                        <div className="plan_duration my-4">
                          <p className="download_app_subtext fs-6 py-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc4) }} />
                        </div>
                        <div className="pb-5 pt-3">
                          <h4 className="text-white cookie_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.title5) }} />
                        </div>
                      </div>
                      <div className="card-body">
                        <p className="footer_subtext pt-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.desc5) }} />
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par5) }} />
                        </div>
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par6) }} />
                        </div>
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par7) }} />
                        </div>
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par8) }} />
                        </div>
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par9) }} />
                        </div>
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            src={hero_tick}
                            width={"23px"}
                            height={"23px"}
                            alt=""
                          />
                          <p className="footer_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpPage.par10) }} />
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            className={`btn border-primary rounded-5 px-4 ${isClicked2 ? 'button_color' : 'butn_color'}`}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop2"
                            onClick={handleClick2}
                          >
                            Register to be Notified
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
              {/* </Slider> */}
            </div>
            {/* end mobile */}
          </div>
        )
      }
    </>
  );
}

export default SignUp;
