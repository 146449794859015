import React from 'react';
import './policy.css'; // Assuming you have a CSS file for styling

const Fees = () => {
    return (
        <div className="privacy-container">
            <div className="top-section">
                <h1>Sample disclosure of fees</h1>
            </div>
            <div className="privacy-container-wrapper">
                <div className="policy-details">
                    <h4>Subject to periodic changes</h4>
                    <br />
                    <p>Many of the fees are external partner fees such as Visa, MasterCard, Bank wire fee or Forex Currency exchange fees.  TagoCash service fees are clearly labeled on the right. </p>
                    <div className='table_body'>
                            <table className="fees-table">
                                <thead>
                                    <tr>
                                        <th>Activity</th>
                                        <th>Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Registering with TagoCash</td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td>Opening an account with TagoCash</td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td>Sending money with TagoCash</td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td>Receive money with TagoCash from (Top Up) or (Cash in)</td>
                                        <td>From Bank/Card fees 2% - 4%</td>
                                    </tr>
                                    <tr>
                                        <td>Uploading money with TagoCash from Bank Account linked</td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td>Uploading money with TagoCash from MoneyGram</td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td>Converting from USD Currency to other Currency</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>Converting from other Currency to USD Currency</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>Withdraw cash from TagoCash (Cashout)</td>
                                        <td>1%</td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fees;
