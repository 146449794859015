import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  app_store_cta,
  google_play_cta,
  currencyanimation,
} from "../../assets/images";
import videoFile from '../../assets/images/hero.webm'
import tagocash_logo from '../../assets/images/tagocash_logo.png'
import Lottie from "react-lottie";
import heroLottie from "../../assets/images/lottieFiles/Globe without text.json";
// import heroLottie from "../../assets/images/lottieFiles/Globe-3D-Coin.json";
import tagoLottie from "../../assets/images/lottieFiles/Logo TagoCash.json";
import axios from "axios"
import DOMPurify from 'dompurify'
import { Base_URL } from "constant/Constant";


const HeroSection = () => {

  const scrollToTopAndNavigate = () => {
    window.scrollTo(0, 0);
  };

  const globeOptions = {
    loop: true,
    autoplay: true,
    animationData: heroLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const globallyOptions = {
    loop: true,
    autoplay: true,
    animationData: tagoLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [homePage, setHomePage] = useState({}); // Use object instead of array

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/home_page`);
        setHomePage(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);


  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-lg-7 heros_cont">
            <h1 className="hero_title text-center text-md-start heading-anim m-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.title) }}>
              {/* <strong>
                <span style={{ color: "#23A6F0", verticalAlign: "middle" }}>
                  Save
                </span>
                <span className="dot">.</span>
              </strong>
              <span style={{ color: "#7A73FF", verticalAlign: "middle" }}>
                  Spend
                </span>
                <span className="dot">.</span>
                <span style={{ verticalAlign: "middle" }}>
                  Send
                </span>
                <span className="dot">.</span>
                <span style={{ color: "#2E3293", verticalAlign: "middle" }}>
                  Cash
                </span> */}
                {/* <span style={{ fontSize: "6rem", margin: "0 0.2em" }}></span> */}
            </h1>  
          {/* <strong>Wherever You Go, <span style={{ color: "#2E3293" }}>Tago!</span></strong> */}

          <h1
            className="hero_subtitle heading-anim text-center m-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc1) }}
          >
            {/* with */}
          </h1>
          <div className="d-flex justify-content-center">
            <div className="tago_lottie">
              <Lottie options={globallyOptions} />
              {/* <img src={tagocash_logo} className="popup-image" width={"260px"} height={"50px"} alt="" /> */}
            </div>
          </div>
          {/* <div className="hero_subtitle subtitle_small text-center text-md-start m-0" >
            <h2
              className="hero_subtitle subtitle_small m-0"
              style={{
                fontWeight: "900",
                lineHeight: '2em',
              }}
            >
              Tago<span style={{ color: "#2E3293" }}>Cash</span> is Your Global Companion
              The best <span style={{color: "#8E00FF"}}>wallet</span> to hold your money.
            </h2>
            <span
              className="sub_text hero_subtext sub-title-style"
            > 
              Don&apos;t Leave Home Without it !
              Turn your money into a currency usable anywhere in the world
            </span>
          </div> */}
          <div className="hero_btn">
            <NavLink className="text-decoration-none google_play" to="/wallet" onClick={scrollToTopAndNavigate}>
              <img
                src={google_play_cta}
                width={"200px"}
                height={"80px"}
                alt={`Blog Cover image`}
                className="google_play"
              />
            </NavLink>
            <NavLink className="text-decoration-none" to="/wallet" onClick={scrollToTopAndNavigate}>
              <img
                src={app_store_cta}
                width={"190px"}
                height={"80px"}
                alt={`Blog Cover image`}
                className="google_play"
              />
            </NavLink>
          </div>

          <div className="align-items-center heros_style">
            <h3
              className="sub_title globally_title safe_width text-center text-md-start" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par) }}>
              {/* Tago<span style={{ color: "#2E3293" }}>Cash</span> turns your money into <br /> a usable currency. */}
            </h3>
            {/* <h3
              className="sub_title globally_title safe_width text-center text-md-start">
              The Safest, Easiest & Most <br /> Secure Way to Hold Money
            </h3> */}
            
          </div>
        </div>
        <div className="col-lg-5 heros_div">
          <div className="hero_animation">
            <Lottie options={globeOptions} style={{borderRadius: "20px"}}/>
          </div>
          {/* <div className="currency_animation_block mt-5">
            <img
              src={currencyanimation}
              style={{ height: "55px" }}
              alt="currencies"
              className="currencyanimation_image_size"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

